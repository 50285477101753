import React, { useContext, useEffect } from 'react'
import { axiosConsolaCaballoNegro, axiosInstance } from '../axios';
import{AUTH_END_POINT_LOGIN_CONSOLA} from '../axios/consolaIntegrationCaballoNegro';
import { notification } from 'antd';
import { UserContext, UserData } from '../Context/UserContext/UsersContext';
import { useNavigate } from 'react-router';
import { addSecondsToDate } from '../utils/addSecondsToDate';
import { BodyContext } from '../Context/BodyContext.tsx/BodyContext';
import { getDateToday } from '../utils/getDateToday';
//import { useRengenerateToken } from './useRengenerateToken';


export const useUser =  ()=>{

    const navigate = useNavigate();
    const {
        setUserData, 
        setCaballoNegroToken,
        setLoading,
        setRegenerateToken,
        setTokenExpirationTime,
        setTokeRefreshTime,
        setSubAgencyData,
     } = useContext(UserContext)

     const {
        setEndDateTickets,
        setStartDateTickets,
        setEndDatePerDay,
        setStartDatePerDay,
        setTypeMovement,
        setLayerBody,
        setNewDataTickets,
        setNewDataPerDay,
        setTicketDetail,
        setSelectedTicket,
        setTicketTransactions
      } = useContext(BodyContext)
    const setAuthorizationHeader = (token: string) => (axiosConsolaCaballoNegro.defaults.headers.common ['Authorization'] = token);

    const removeAuthorizationHeader = ( ) => {delete axiosConsolaCaballoNegro.defaults.headers.common ['Authorization'];}


    const logOut = ()=>{
        setCaballoNegroToken('undefined')
        setRegenerateToken('undefined')
        setTokenExpirationTime('undefined')
        setTokeRefreshTime('undefined')
        window.location.reload();

        // setTypeMovement('per_day')
        // setLayerBody('movements')
        // setUserData(undefined) 
        // setCaballoNegroToken(undefined)
        // setRegenerateToken(undefined)
        // setLoading(false)
        // setRegenerateToken(undefined)
        // setTokenExpirationTime(undefined)
        // setTokeRefreshTime(undefined)
        // setStartDateTickets(getDateToday().tickets_StartDate)
        // setEndDateTickets(getDateToday().ticket_endDate)
        // setStartDatePerDay(getDateToday().perDay_StartDate)
        // setEndDatePerDay(getDateToday().perDay_endDate)
        // setSubAgencyData(undefined)
        // setNewDataTickets(undefined)
        // setNewDataPerDay(undefined)
        // setTicketDetail(undefined)
        // setSelectedTicket(undefined)
        // setTicketTransactions(undefined)
    }
    
    const requestAccess = async(usuario:string, password:string, key: string)=>{
        let version = 1;
       
        try{
           const data = await axiosConsolaCaballoNegro.get(
            AUTH_END_POINT_LOGIN_CONSOLA,
                {
                    params: {
                        usuario: usuario,
                        password: password,
                        version: version,
                        clave: key,
                    },
                }
            )
           if(data.data.error == 0){
            let token_life_date = addSecondsToDate(data.data.token_vida)            
            let token_regen_life_date = addSecondsToDate(data.data.token_reneg_vida) 
            setUserData(data.data)
            setCaballoNegroToken(data.data.token)
            setRegenerateToken(data.data.token_reneg)
            setTokenExpirationTime(token_life_date.date)
            setTokeRefreshTime(token_regen_life_date.date)           
            setLoading(false)
            //getRegenerateToken(data.data.token)
           } else{
            return data.data
           } 
        }catch(e){
            notification.error({
                message: 'Error', 
                description:
                  'Hubo un error, por favor comuníquese con el administrador', 
                placement: 'top'
            })
        } 
    }
    
   return{requestAccess, logOut}
}