import React, { useContext } from 'react'
import { axiosConsolaCaballoNegro, axiosInstance } from '../axios';
import{
  API_END_POINT_REPORTE_TICKETS_FECHA,
  API_END_POINT_REPORTE_TOKEN_RENEG
} from '../axios/consolaIntegrationCaballoNegro';
import { notification } from 'antd';
import { UserContext } from '../Context/UserContext/UsersContext';
import { BodyContext } from '../Context/BodyContext.tsx/BodyContext';
import { addSecondsToDate } from '../utils/addSecondsToDate';
import { useUser } from './useUser';


export const usePerDayReport =  ()=>{

  const { 
    caballoNegroToken,
    setCaballoNegroToken,
    setRegenerateToken,
    regenerateToken,
    tokenExpirationTime,
    tokeRefreshTime,
    setTokeRefreshTime,
    setTokenExpirationTime,
    setLoading,
    userData,
    subAgencydata,
  } = useContext(UserContext)

  const {
    setNewDataPerDay,
    setEndDatePerDay,
    setStartDatePerDay,
    setLoadingBody,
    loadingBody
  } = useContext(BodyContext)

  const {logOut}= useUser()
    
  

  const fetcher = async (
      values:{
        startDate: string,
        endDate: string,
        selectedSubAgency?: string
      },      
      newToken?: string,
      
      ): Promise<void>  => {
      const token = newToken ? newToken : caballoNegroToken
      try {
        setLoadingBody({
          ...loadingBody,
          perDayReport: true
        }) 
        let id_according_to_level = values.selectedSubAgency ? values.selectedSubAgency : userData?.nivel_agencia == '1' ? userData.id_agencia : subAgencydata?.id_agencia
        const {
          data,
        }: { data: any } = await axiosConsolaCaballoNegro.get(
          `${API_END_POINT_REPORTE_TICKETS_FECHA}?token=${caballoNegroToken}&agencia=${id_according_to_level}&fecha_desde=${values.startDate}&fecha_hasta=${values.endDate}`
        );          
        if(data.respuesta.error =='0'){
          setLoading(false)
          setStartDatePerDay(values.startDate)
          setEndDatePerDay(values.endDate)                
          setNewDataPerDay(data.registros);
          setLoadingBody({
            ...loadingBody,
            perDayReport: false
          }) 
          if(data.registros == null){
           // setStartDatePerDay(undefined)
           // setEndDatePerDay(undefined) 
            notification.info({
              message: 'Resultado', 
              description:
                'No hay registros de movimientos para ésta solicitud, realice otra consulta', 
              placement: 'topRight'
            })
          }
        }else {
          setLoading(false)
          notification.error({
            message: 'Error', 
            description:
              'ha ocurrido un error, comuníquese con el administrador', 
            placement: 'top'
          })
          setLoadingBody({
            ...loadingBody,
            perDayReport: false
          }) 
        }
      } catch (newError) {
        setLoadingBody({
          ...loadingBody,
          perDayReport: false
        }) 
        setLoading(false)
        console.log(newError);
        throw newError;
      }
    };
      
    const filterByDatePerDate = async(
      startDate: string,
      endDate: string,
      selectedSubAgency?:string
    )=>{
      {
        let currentDateAndTime = new Date();
        if(tokenExpirationTime && tokeRefreshTime){

          let date_token_vida = new Date(tokenExpirationTime)
          let date_token_regen = new Date(tokeRefreshTime)
          if(currentDateAndTime < date_token_vida ){ //consulto si se venció el token
            fetcher({startDate, endDate, selectedSubAgency })
          }else{ 
            try {
              const {
                data,
              }: { data: any } = await axiosConsolaCaballoNegro.get(
              `${API_END_POINT_REPORTE_TOKEN_RENEG}?token=${regenerateToken}`
              );          
              if(data){
                setLoading(false)
                switch(data.error){
                  case '0':  
                    let token_life = addSecondsToDate(data.token_vida)            
                    let token_regen_life = addSecondsToDate(data.token_reneg_vida)   
                    setCaballoNegroToken(data.token)  
                    setRegenerateToken(data.token_reneg)
                    setTokenExpirationTime(token_life.date)
                    setTokeRefreshTime(token_regen_life.date)  
                    fetcher({startDate, endDate, selectedSubAgency}, data.token_reneg,)
                    break;
                  case '-3':
                    logOut()
                    notification.error({
                      message: 'Logout', 
                      description:
                      'La sesión se ha vencido, vuelva a loguearse', 
                      placement: 'top'
                    })
                  break;
                  default: 
                    console.log(data.error)
                }
              }
            } catch (newError) {
              logOut()
              setLoading(false)
              console.log(newError);
              throw newError;
            }       
          }          
        }
      }
    }


    
   return{filterByDatePerDate}
}