import React, {useContext, useState} from 'react';
import { Form, Button, Input, Row, Col } from 'antd';
import { BodyContext } from '../../Context/BodyContext.tsx/BodyContext';
import './styles.scss'
import { useSearchTicket } from '../../Services/useSearchTicket';
import { MobileContext } from '../../Context/MobileContext/MobileContext';



export const Panel_SearchTickets: React.FC =()=>{

  const {
    setTicketsLoading,
    ticketsLoading,
    setNewDataTickets,
    setSearchTicketsNull,
  } = useContext(BodyContext)

  const {controllerNavbarBottom, setControllerNavbarBottom} = useContext(MobileContext)


  const [form] = Form.useForm();
  const [formData, setFormData] = useState<string>('')

  const [loadingSearch, setLoadingSearch] = useState(false)
  const {searchTicket} = useSearchTicket()  
 
  const handleSearch = async () =>{
    setSearchTicketsNull(undefined)
    setNewDataTickets(undefined)
    setTicketsLoading({
      ...ticketsLoading,
      loading: true,
    }) 
    setControllerNavbarBottom({
        movementsPerDayRangePicker: false,
        movementsTicketsRangePicker: false,
        searchTicket: false,
        showTickets: true,
        transactionsPerDayRangePicker: false,
        transactionsRangePicker: false,
        transactions: false,
        showTransactions: false
    });
    await searchTicket(formData, 'shareTicket')
  }

  const handleChange = (event: any)=>{
    event.preventDefault()
    const name = event.target.name 
    const value = event.target.value
    setFormData(value)
  };
   
  const handleReset = ()=>{
    setNewDataTickets(undefined) 
    form.setFieldsValue({
      ticketsNumber: ''  
    });
  } 

  return(
    <div className={`searchTickets_container ${controllerNavbarBottom.searchTicket  ? 'visible' : 'hidden'}`}>  
    <div className='mobilMovementsFiltersTickets_subContainers'>
      <p><div className='searchTickets_point'> </div>BUSCAR TICKET</p>       
      <Form
        form={form}
        style={{ height: '40px'}}
      >      
        <Form.Item
          style={{color:'white', marginRight:'10px'}}
          name="ticketsNumber"
          className="searchTickets_selectColor"
        >
          <Input
            style={{
              backgroundColor: 'transparent', 
              color: 'white', 
              borderColor: 'white', }}
            type='number'
            placeholder='N° ticket'
            onChange={handleChange}
            value={formData}
        />                
        </Form.Item >             
          <Row>
            <Col 
              span={12}
              style={{
                display:'flex',
                justifyContent:'center'

              }}
            > 
              <Form.Item
                className='searchTickets_container-btn'   
              >    
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loadingSearch}
                  onClick={handleSearch}
                  style={{width:'90px'}}
                >
                  Buscar
                </Button>
              </Form.Item>
            </Col>
            <Col 
              span={12}
              style={{
                display:'flex',
                justifyContent:'center'

              }}  
            >
              <Button
                  type="primary"
                  loading={loadingSearch}
                  onClick={handleReset}
                  style={{width:'90px'}}
                >
                  Cancelar
              </Button>  
            </Col>
          </Row>                                  
        </Form>    
      </div>          
    </div>
  )
}

export default Panel_SearchTickets;

