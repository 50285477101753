import axios from 'axios';
import { API_URLs } from './constants';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,//process.env.REACT_APP_API_ENV
});

export const axiosConsolaCaballoNegro = axios.create({
  baseURL: 'https://turf365.net/webservice/Consola_ext',
});





