import React, { useContext, useState, useEffect, useRef } from 'react';
import { UserContext } from '../../Context/UserContext/UsersContext';
import './styles.scss'
import Table, { ColumnsType, TableProps } from 'antd/es/table';
import { TransactionsContext } from '../../Context/TransactionsContext.tsx/TransactionsContext';
import { BodyContext } from '../../Context/BodyContext.tsx/BodyContext';
import { useTicketData } from '../../Services/useTicketData';
import { useSearchTicket  } from '../../Services/useSearchTicket';
import { ModalTickets } from '../../Components/ModalTickets';
import { TotalBalanceOfTransactions } from '../../Components/TotalBalanceTransactions/TotalBalanceOfTransactions';
import { SpinerHorizontal } from '../../Components/SpinerHorizontal/SpinerHorizontal';
import { TotalBalance_transactions } from '../TotalBalance_transactions/TotalBalance_transactions';
import { MobileContext } from '../../Context/MobileContext/MobileContext';

export interface RowDataTransactions {
    credit: number | string,
    debit: string,
    fecha: string,
    id: string,
    monto: string,
    transactionId: string,
    transactionType: string, //'BET
    userId: string,
    roundId: string
}

interface AllTransactionsProps {}

export const Screen_AllTransactions: React.FC<AllTransactionsProps> =()=>{

    const { subAgencydata, userData} = useContext(UserContext)
    const { 
        dateAndHoursAllTransactions, 
        dataOfAllTransactions,
        transactionsLoading,
        setTableTransactionsData,
        detailDataTransaction,
        setDetailDataTransaction
    } = useContext(TransactionsContext)

    const {
        setTicketDetail,
    } = useContext(BodyContext)   
    const {ticketData: ticketDataDetail} = useTicketData()
    const {searchTicket} = useSearchTicket()
    const {controllerNavbarBottom, setControllerNavbarBottom} = useContext(MobileContext)
 
    const[idAccordingToLevel, setIdAccordingToLeave] =useState<string | undefined>(userData?.nivel_agencia == '1' ? userData.id_agencia : subAgencydata?.id_agencia)
    const [selectedTicketId, setSelectedTicketId] = useState<string | undefined>()
    const [isModalOpen, setIsModalOpen] = useState(false);

    const moneda = userData?.nivel_agencia == '1' ? userData.moneda_resum : subAgencydata?.moneda_resum

    useEffect(()=>{
        if(idAccordingToLevel !== subAgencydata?.id_agencia){
            setIdAccordingToLeave(subAgencydata?.id_agencia)
        }
    },[ dataOfAllTransactions])

   
   const rowDataTransactions= ()=>{
        let data: RowDataTransactions[]=[];
        let moneda = userData?.nivel_agencia == '1' ? userData.moneda_resum : subAgencydata?.moneda_resum
        dataOfAllTransactions?.map((dataOfAllTransaction)=>{
            data.push({
                credit: Intl.NumberFormat("de-DE").format(Number(dataOfAllTransaction.credit)) + ' ' + moneda,
                debit: Intl.NumberFormat("de-DE").format(Number(dataOfAllTransaction.debit)) + ' ' + moneda,
                fecha: dataOfAllTransaction.fecha,
                id: dataOfAllTransaction.id,
                monto: Intl.NumberFormat("de-DE").format(Number(dataOfAllTransaction.monto)) + ' ' + moneda,
                transactionId: dataOfAllTransaction.transactionId,
                transactionType: dataOfAllTransaction.transactionType, //'BET
                userId: dataOfAllTransaction.userId,
                roundId: dataOfAllTransaction.roundId
               
            })
        })
        return data
    }

   const handleRenderer = (text: string, event: RowDataTransactions,  ) =>{
        return<a>{text}</a>
    }

    const filtersByTransactions= () => {
        let filter: {text: string, value: string}[] = [];
        if (dataOfAllTransactions) {
            dataOfAllTransactions.forEach((item) => {
                const value = item.id;
                if (!filter.some(existingItem => existingItem.value === value)) {
                    filter.push({
                        text: value,
                        value: value,
                    });
                }
            });
        }
        return filter;
    };

    const handleClickDetail = async (event: RowDataTransactions) =>{
        setControllerNavbarBottom({
            ...controllerNavbarBottom,
            showTransactions: false
          });
        setIsModalOpen(true)
        setSelectedTicketId(event.roundId)
        if(event.roundId){
            await ticketDataDetail(event.roundId)
            await searchTicket(event.roundId, 'detailTransaction')
            setIsModalOpen(true)
        }
    }

    const filtersByIdUser = () => {
        let filter: {text: string, value: string}[] = [];
        if (dataOfAllTransactions) {
            dataOfAllTransactions.forEach((item) => {
                const value = item.userId;
                if (!filter.some(existingItem => existingItem.value === value)) {
                    filter.push({
                        text: value,
                        value: value,
                    });
                }
            });
        }
        return filter;
    };
  
 
   const columns: ColumnsType<RowDataTransactions> = [
    {
        title: 'N°',
        dataIndex: 'id',
        key: 'id',
        render: handleRenderer,
        // filters: filtersByTransactions(),
        // onFilter: (value, record) => typeof value === 'string' && record.id.includes(value),
        // filterSearch: true,
    },
    {
        title: 'fecha',
        dataIndex: 'fecha',
        key: 'fecha',
        render: handleRenderer,
    },
    {
        title: 'Tipo de Transacción',
        dataIndex: 'transactionType',
        key: 'transactionType',
        render: handleRenderer,
    },
    {
        title: 'Usuario',
        dataIndex: 'userId',
        key: 'userId',
        render: handleRenderer,
        filters: filtersByIdUser(),
        onFilter: (value, record) => typeof value === 'string' && record.userId.includes(value),
        filterSearch: true,
    },
    {
        title: 'Credito',
        dataIndex: 'credit',
        key: 'credit',
        render: handleRenderer,
    },
    {
        title: 'Débito',
        dataIndex: 'debit',
        key: 'debit',
        render: handleRenderer,
    },  
    {
        title: 'Total',
        dataIndex: 'monto',
        key: 'monto',
        render: (text)=><a style={{color:'orange'}}>{text}</a>,
    },    
    {
        title: 'Detalle',
        dataIndex: 'rowDataTransactions',
        key: 'rowDataTransactions',
        render: (text, event: RowDataTransactions)=><p 
            className='mobile_allTransactions_detail'
            onClick={()=>handleClickDetail(event)}
            >
                Detalle
            </p>,
    },  
];
    const handleCancelModal = () => {        
        setIsModalOpen(false);
        setTicketDetail(undefined)
        setSelectedTicketId(undefined)
        setDetailDataTransaction(undefined)
        setTimeout(()=>{
            setControllerNavbarBottom({
                ...controllerNavbarBottom,
                showTransactions: true
              });
          },300)
       
    };

    const onChange: TableProps<RowDataTransactions>['onChange'] = (pagination, filters, sorter, extra) => {
        if(extra){
           setTableTransactionsData(extra.currentDataSource)
        } 
    };
  
    return(
        <div className='Screen_AllTransactions_containers'>
            {isModalOpen && moneda && selectedTicketId && detailDataTransaction &&
                <ModalTickets 
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    handleCancel={handleCancelModal}
                    moneda={moneda}
                    selectedTicketId={selectedTicketId}                    
                    setSelectedTicketId={setSelectedTicketId}
                />
            }
           {moneda && dataOfAllTransactions && dataOfAllTransactions.length !== 0 &&  dateAndHoursAllTransactions &&
                <>
                    <p className='p_gmt'>Zona horaria: GMT-3.</p>
                    <TotalBalance_transactions 
                        moneda={moneda} 
                        startDateTransactions= {dateAndHoursAllTransactions.firstDate + ' ' + dateAndHoursAllTransactions.firstHours}
                        endDateTransactions={dateAndHoursAllTransactions.secondDate + ' ' + dateAndHoursAllTransactions.secondHours}
                        type={'allTransactions'}
                    />
                </>
            }        
            { dataOfAllTransactions && dataOfAllTransactions.length !== 0 && controllerNavbarBottom.showTransactions &&
                <Table 
                    columns={columns} 
                    dataSource={rowDataTransactions()}  
                    scroll={{ x: 1500 }}
                    onChange={onChange}
                />
            }
            {transactionsLoading.getTransactionsPerDay &&  <SpinerHorizontal info={'Loading'}/>}
        </div>
    )
}

   
