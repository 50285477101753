import { Col, Row } from "antd";
import React, { useCallback, useContext } from "react";
import { TicketsPerDayReport } from "../../Services/useGetPerDayReport";
import { BodyContext } from "../../Context/BodyContext.tsx/BodyContext";
import { TicketsReport } from "../Screen_MovementsTickets/Screen_MovementsTickets";

export interface RowDataSaldo {
    totalBet: string,
    totalWon: string,
    totalReturned: string,
    total: string,    
}

interface TotalBalanceMobile{
    moneda: string,
    startDateTickets: string,
    endDateTickets: string,
    type: 'perDay' | 'tickets'
}

export const TotalBalance_movements: React.FC<TotalBalanceMobile> =({
    moneda,
    startDateTickets,
    endDateTickets,
    type
})=>{

    const {
        newDataTickets,
        newDataPerDay
    } = useContext(BodyContext)

    const style={        
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px',
        fontWeight: '500'        
    }

    const roeSecond={
        color:'orange',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px',
        fontWeight: '500'
    }
    
    const totalBalance = useCallback (()=>{
        let totalApuesta = 0;
        let totalDevolución = 0;
        let totalGanado = 0;

        let data = type ==='tickets' ? newDataTickets : newDataPerDay
        data?.map((item: TicketsReport | TicketsPerDayReport)=>{
            if(item.cancelado !='1'){
                totalApuesta = totalApuesta + parseFloat(item.total_apuesta);
                totalDevolución = totalDevolución + parseFloat(item.total_devolucion);
                totalGanado = totalGanado + parseFloat(item.total_ganado);
            }
        })
        let totalBalance= {
            totalApuesta: totalApuesta,
            totalDevolución: totalDevolución,
            totalGanado: totalGanado,
            totalSaldo:  totalApuesta - totalDevolución - totalGanado
        }

        let totalBetCalculated = Intl.NumberFormat("de-DE").format(totalBalance.totalApuesta) + ' ' + moneda
        let totalWonCalculated = Intl.NumberFormat("de-DE").format(totalBalance.totalGanado) + ' ' + moneda
        let totalReturned = Intl.NumberFormat("de-DE").format(totalBalance.totalDevolución) + ' ' + moneda
        let totalSaldo = Intl.NumberFormat("de-DE").format(totalBalance.totalSaldo) + ' ' + moneda

        return {
            totalBet: totalBetCalculated,
            totalWon: totalWonCalculated,
            totalReturned: totalReturned,
            total: totalSaldo
        }

    }, [newDataTickets, newDataPerDay])

    const calculateNumberOfTickets = useCallback(() =>{
        let numeberOfTickets: number = 0
        newDataPerDay?.map((item)=>{
            numeberOfTickets = numeberOfTickets + parseInt(item.cantidad_tickets)
        })
        return numeberOfTickets 
    }, [newDataPerDay])

    const TicketsCanceled = ()=>{
        let n = 0
        let total = 0
        newDataTickets?.map((item)=>{
            if (item.cancelado === '1'){
                n = n + 1
            }
        })
        if(newDataTickets){
            total =  newDataTickets.length - n
        }
        return total
    }

    return( 
        <div style={{
            background: 'white', 
            padding:'15px', 
            borderRadius: '5px',
            marginBottom: ' 10px'}}>
            <Row style={{borderTopLeftRadius: '5px', borderTopRightRadius: '5px',  border: '1px solid gray'}}>
                <Col style={style} span={12}>Fecha desde</Col>
                <Col style={style} span={12}>Fecha hasta</Col>
            </Row>
            <Row style={{ 
                borderBottomLeftRadius:'5px', 
                borderBottomRightRadius:'5px', 
                borderBottom: '1px solid gray',
                borderLeft: '1px solid gray',
                borderRight: '1px solid gray',
            }}>
                <Col style={roeSecond} span={12}>{startDateTickets}</Col>
                <Col style={roeSecond} span={12}>{endDateTickets}</Col>
            </Row>
            <Row style={{margin:'8px 0px 0px 8px'}}>            
                <Col span={10}> 
                    <p style={{color:'gray', fontSize:'13px'}}>Total Tickets: </p>
                    <p style={{color:'gray', fontSize:'13px'}}>Total apostado: </p>
                    <p style={{color:'gray', fontSize:'13px'}}>Total ganado:</p>
                    <p style={{color:'gray', fontSize:'13px'}}>Total devuelto: </p>
                    <p style={{color:'gray', fontSize:'13px'}}>Saldo total: </p>
                </Col>
                <Col  span={14}>
                    <p style={{color:'black', fontSize:'13px'}}> {type === 'tickets' ?  TicketsCanceled() : calculateNumberOfTickets() }</p>
                    <p style={{color:'black', fontSize:'13px'}}> {totalBalance().totalBet}</p>
                    <p style={{color:'black', fontSize:'13px'}}> {totalBalance().totalWon}</p>
                    <p style={{color:'black', fontSize:'13px'}}> {totalBalance().totalReturned}</p>
                    <p style={{color:'orange', fontSize:'13px', fontWeight:'500'}}> {totalBalance().total}</p>        
                </Col>
            </Row>
        </div>
    )
}    

