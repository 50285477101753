import { useNavigate  } from "react-router-dom";
import { notification } from "antd";
import useSWR from "swr";
import { axiosConsolaCaballoNegro } from '../axios';
import {API_END_PINT_LISTA_AGENCIA_CONSOLA_N0, API_END_POINT_REPORTE_TICKETS_FECHA, API_END_POINT_REPORTE_TOKEN_RENEG} from '../axios/consolaIntegrationCaballoNegro'
import { BodyContext } from "../Context/BodyContext.tsx/BodyContext";
import { useContext } from "react";
import { UserContext } from "../Context/UserContext/UsersContext";
import { addSecondsToDate } from "../utils/addSecondsToDate";
import { useUser } from "./useUser";


export interface Record {
  cantidad_afiliados: string,
  cantidad_usuarios: string,
  descripcion: string,
  id_agencia: string,
  moneda: string,
  moneda_desc: string,
  moneda_resum: string,
  num_usuario: string,
  saldo: number
}


export const useGetlistsAgencies = (
  token: string | undefined, 
  ) => {

    const { 
      setCaballoNegroToken,
      setRegenerateToken,
      regenerateToken,
      setTokeRefreshTime,
      setTokenExpirationTime,
      setLoading,
      userData
    } = useContext(UserContext)

    const {logOut}= useUser()
  
    const getRegenerateToken = async(
    )=>{
      try {
        const {
          data,
        }: { data: any } = await axiosConsolaCaballoNegro.get(
        `${API_END_POINT_REPORTE_TOKEN_RENEG}?token=${regenerateToken}`
        );          
        if(data){
          setLoading(false)
          switch(data.error){
            case '0':  
              let token_life = addSecondsToDate(data.token_vida)            
              let token_regen_life = addSecondsToDate(data.token_reneg_vida)   
              setCaballoNegroToken(data.token)  
              setRegenerateToken(data.token_reneg)
              setTokenExpirationTime(token_life.date)
              setTokeRefreshTime(token_regen_life.date)  
              break;
            case '-100':
              console.log('')
              break;
            case '-3':
               logOut()
              notification.error({
                message: 'Logout', 
                description:
                  'La sesión se ha vencido, vuelva a loguearse', 
                placement: 'top'
              })
            break;
            default: 
              console.log('')
            break;
          }
        }
      } catch (newError) {
        console.log(newError);
        throw newError;
      }  
    }
    
    const fetcher = async (
      path: string,
      token: string | undefined): Promise<any[] | undefined> => {
      try {
        const {
          data,
        }: { data: any } = await axiosConsolaCaballoNegro.get(
          path,
            {
                params: {
                    id_agencia: userData?.id_agencia,
                    token: token 
                },
            }
        );
        switch(data.respuesta.error){
          case '0':
            return data.registros
          case '-100':
            getRegenerateToken()
            break;
          case '-2':
            logOut()
            console.log('error -2')
            break;
          default:
            notification.error({
              message: 'Error', 
              description:
                'Hubo un error, comuníquese con el administrador', 
              placement: 'top'
          })
        }
      } catch (newError) {
        console.log(newError);
        throw newError;
      }
    };
  
    const { data } = useSWR([API_END_PINT_LISTA_AGENCIA_CONSOLA_N0,  token], ([url, token]) => fetcher(url, token))
    
  return {
    data,
  };
};

