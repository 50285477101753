import React, { useContext, useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import Movements from '../../Components/Movements/Movements';
import VerticalNavbar from '../../Components/VerticalNavbar/VerticalNavbar';
import { BodyContext, Navbar as NavbarType } from '../../Context/BodyContext.tsx/BodyContext';
import Home from '../../Components/Home/Home';
import Configurations from '../../Components/Configurations/Configurations';
import AnimationLoading from '../../Components/Spiner/AnimationLoading';
import { UserContext } from '../../Context/UserContext/UsersContext';
import { useGetUserData } from '../../Services/useGetUserData';
import './styles.scss'
import Transactions from '../../Components/Transactions/Transactions';
import { usePerDayReport } from '../../Services/usePerDayReport';


export const Dashboard: React.FC =()=>{
    
    const {layerBody, setLayerBody, startDatePerDay, endDatePerDay,} = useContext(BodyContext)
    const {filterByDatePerDate } = usePerDayReport()
    const {loading, caballoNegroToken, userData, setUserData} = useContext(UserContext)
    const {data} = useGetUserData(caballoNegroToken)
    const {data: isLogueado} = useGetUserData(caballoNegroToken)
    const [changeNavbar, setChangeNavbar] = useState<NavbarType>('movements')

    useEffect(()=>{
        if(isLogueado){
            setUserData(isLogueado)
            if(userData?.nivel_agencia == '1' ){
                filterByDatePerDate(startDatePerDay, endDatePerDay, userData.id_agencia)
            }  
        }
    },[isLogueado])
    
    return(
        <div className="dashboard_container">
            {loading && <AnimationLoading/>}
            <div className='dashboard_navbar'>
                <VerticalNavbar changeNavbar={changeNavbar} setChangeNavbar={setChangeNavbar}/>
            </div>
            <div className='dashboard_body'>
                <Navbar changeNavbar={changeNavbar} setChangeNavbar={setChangeNavbar}/>
                {layerBody === 'movements' &&  <Movements/>}
                {layerBody === 'transactions' &&  <Transactions/>}
                {layerBody === 'home' &&  <Home/>}
                {layerBody === 'logout' &&  <Configurations/>}
            </div>       
        </div>
    )
}