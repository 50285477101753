import React, { useContext, useEffect, useState } from "react";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import { Button } from "antd";
import { BodyContext } from "../../Context/BodyContext.tsx/BodyContext";
import { ExportCSVProps } from "./type";
import {  TicketsReport } from "./MovementsTickets";

export interface ExcelTicketsData {
  numberTickets: string,
  racecourse: string,
  raceDate: string,
  ticketDate: string,
  raceNumber: string,
  user: string,
  userNumber: string,
  totalBet:  string,
  totalWon: string,
  totalReturned: string  
  total: string
  cancelled?: any,

}

const ExportCSV: React.FC<ExportCSVProps> = ({data}) => {
  const { 
    tableTicketData, 
    newDataTickets,
    //searchTickets
  } = useContext(BodyContext)

  const [customers, setCustomers] = useState<ExcelTicketsData[]>(excelRowData());

  useEffect(()=>{
    if(newDataTickets){
      let tickets: ExcelTicketsData[] = [];
      if(tableTicketData ){
        tableTicketData.map((item)=>{
          tickets.push( {  
            numberTickets: item.numberTickets,
            racecourse: item.racecourse,
            raceDate: item.raceDate,
            ticketDate: item.ticketDate,
            raceNumber: item.raceNumber,
            user: item.user,
            userNumber: item.userNumber,
            totalBet:  item.totalBet,
            totalWon: item.totalWon,
            totalReturned: item.totalReturned,  
            total: item.total,
          })
        })
        setCustomers(tickets)
      }  
    }   
  },[tableTicketData])

  useEffect(()=>{
    if(newDataTickets){
      let tickets: ExcelTicketsData[] = [];
      newDataTickets.map((item, index)=>{
        let totalSaldo = Intl.NumberFormat("de-DE").format((parseFloat(item.total_apuesta) - parseFloat(item.total_devolucion) - parseFloat(item.total_ganado)))
        tickets.push({
          numberTickets: item.num_ticket,
          racecourse: item.desc_hipodromo,
          raceDate: item.fecha,
          ticketDate: item.fecha_ticket,
          raceNumber: item.num_carrera,
          user: item.usuario,
          userNumber: item.num_usuario,
          totalBet:  item.total_apuesta,
          totalWon: item.total_ganado,
          totalReturned: item.total_devolucion,
          total: item.cancelado === '0'
              ? totalSaldo
              : 'Cancelado',
        })
      })
      setCustomers(tickets)
    }   
  },[newDataTickets])
    
  function excelRowData(){
    let tickets: ExcelTicketsData[] = [];
    data.map((item, index)=>{
      let totalSaldo = Intl.NumberFormat("de-DE").format((parseFloat(item.total_apuesta) - parseFloat(item.total_devolucion) - parseFloat(item.total_ganado)))
      tickets.push({
        numberTickets: item.num_ticket,
        racecourse: item.desc_hipodromo,
        raceDate: item.fecha,
        ticketDate: item.fecha_ticket,
        raceNumber: item.num_carrera,
        user: item.usuario,
        userNumber: item.num_usuario,
        totalBet:  item.total_apuesta,
        totalWon: item.total_ganado,
        totalReturned: item.total_devolucion,
        total: item.cancelado === '0'
            ? totalSaldo
            : 'Cancelado'
      })
    })
    return tickets
  }

  const wscols = [
    { wch: Math.max(...customers.map(customer => 9)) },//'N° Ticket'
    { wch: Math.max(...customers.map(customer => customer.racecourse.length + 2)) },//'Hipódromo
    { wch: Math.max(...customers.map(customer => customer.raceDate.length + 2)) },//Fecha Carrera',
    { wch: Math.max(...customers.map(customer => customer.ticketDate.length + 2)) },//'Fecha ticket',
    { wch: Math.max(...customers.map(customer => 12)) }, //'N° Carrera',
    { wch: Math.max(...customers.map(customer => customer.user.length + 2)) }, //Usuario
    { wch: Math.max(...customers.map(customer => customer.userNumber.length + 2)) }, // 'N° Usuario'
    { wch: Math.max(...customers.map(customer => customer.totalBet.length  + 2)) },
    { wch: Math.max(...customers.map(customer => customer.totalWon.length + 2))},
    { wch: Math.max(...customers.map(customer => 10)) },
    { wch: Math.max(...customers.map(customer => customer.total.length + 2)) },
  ];

  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const Heading = [
    {
      numberTickets: 'N° Ticket',
      racecourse: 'Hipódromo',
      raceDate: 'Fecha Carrera',
      ticketDate: 'Fecha ticket',
      raceNumber: 'N° Carrera',
      user: 'Usuario',
      userNumber: 'N° Usuario',
      totalBet: 'Apostado',
      totalWon: 'Ganado',
      totalReturned: 'Devuelto',
      total: 'Total',
    }
  ]

  const fileName = "Tickets"

  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(Heading, {
      header: [
        "numberTickets",
        "racecourse",
        "raceDate",
        "ticketDate",
        "raceNumber",
        "user",
        "userNumber",
        "totalBet",
        "totalWon",
        "totalReturned",
        "total",
      ],
      skipHeader: true,
      //origin: 0 //ok
    });
    ws["!cols"] = wscols;
    XLSX.utils.sheet_add_json(ws, customers, {
      header: [
        "numberTickets",
        "racecourse",
        "raceDate",
        "ticketDate",
        "raceNumber",
        "user",
        "userNumber",
        "totalBet",
        "totalWon",
        "totalReturned",
        "total",
      ],
      skipHeader: true,
      origin: -1 //ok
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      type="ghost"
      className="movements_upload"
      onClick={e => exportToCSV()}
    >
      Exportar tabla
    </Button>
  );
};

export default ExportCSV;
