import React, {useContext, useState} from 'react';
import { Form, Button, DatePicker } from 'antd';
import { BodyContext } from '../../Context/BodyContext.tsx/BodyContext';
import { useTicketsReport } from '../../Services/useTicketsReport';
import './styles.scss'
import { useSearchTicket } from '../../Services/useSearchTicket';
import { Dayjs } from 'dayjs';
import { MobileContext } from '../../Context/MobileContext/MobileContext';
import { useNavbarBottom } from '../NavbarBottom/useNavbarBottom';


import dayjs from 'dayjs';
import type { RangePickerProps } from 'antd/es/date-picker';

type RangeValue = [Dayjs | null, Dayjs | null] | null;

export const Panel_MovementsFiltersTickets: React.FC =()=>{
  const [dates, setDates] = useState<Dayjs | null>(null);
  const [value, setValue] = useState<RangeValue>(null);

  const { 
    setTicketsLoading,
    ticketsLoading,
    setIsLoadingAllTickets,
    setNewDataTickets,
    setSearchTicketsNull,
    seeAllTickets, 
    setSeeAllTickets
  } = useContext(BodyContext)

  const {filterByDate} = useTicketsReport()
  const { RangePicker } = DatePicker;
  const {
    onClick_navbarBottom
  } = useNavbarBottom()     

  const [form] = Form.useForm();
  const [form_Tickets_picker] = Form.useForm();
  const [formData, setFormData] = useState<string>('')
  const {searchTicket} = useSearchTicket()  
  
  const {
    controllerNavbarBottom,  
    setControllerNavbarBottom
  } = useContext(MobileContext)
 
  const handleSearch = async (event: any) =>{
    setSearchTicketsNull(undefined)
    setNewDataTickets(undefined)
    setTicketsLoading({
      ...ticketsLoading,
      loading: true,
    }) 
    await searchTicket(formData, 'shareTicket')
  }

  const handleChange = (event: any)=>{
    event.preventDefault()
    const name = event.target.name 
    const value = event.target.value
    setFormData(value)
  };
    
  const handleCalendar = async()=>{
    const dateFrom = form_Tickets_picker.getFieldValue('dateFrom')
    const dateUntil = form_Tickets_picker.getFieldValue('dateUntil')
    setSearchTicketsNull(undefined)
    setTicketsLoading({
      ...ticketsLoading,
      loading: true,
    })       
    const startDate = dateFrom.format('YYYY/M/DD')
    const dateEnd =  dateUntil.format('YYYY/M/DD')
    setNewDataTickets(undefined)
    await filterByDate(startDate, dateEnd)
    form_Tickets_picker.resetFields()    
    setControllerNavbarBottom({
      ...controllerNavbarBottom,
      movementsTicketsRangePicker: false
    });
  }

  const handleSeeAll =()=>{
    setSearchTicketsNull(undefined)
    setSeeAllTickets(!seeAllTickets)
    if(!seeAllTickets){
      setIsLoadingAllTickets(true)
      setTimeout(()=>{
        setIsLoadingAllTickets(false)
      },2000)
    }
  }

  const handleReset = ()=>{
    setNewDataTickets(undefined) 
    form.setFieldsValue({
      ticketsNumber: ''  
    });
  } 

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    if (!dates) {
      // Deshabilita todas las fechas antes de seleccionar una o si dates es nulo
      return true;
    }
  
    // Calcula la diferencia en días entre la fecha seleccionada y la fecha actual
    const diffDays = dates.diff(dayjs(), 'days');
  
    // Habilita solo los dos días siguientes a la fecha seleccionada en dates
    return current.isBefore(dates, 'day') || current.isAfter(dates.add(2, 'days'), 'day');
  };

  return(   
    <div className= {`mobilMovementsFiltersTickets_container ${controllerNavbarBottom.movementsTicketsRangePicker  ? 'visible' : 'hidden'}`} >       
      <div className='mobilMovementsFiltersTickets_subContainers'>
        <p><div className='mobilMovementsFiltersTickets_point'> </div> BUSQUEDA POR FECHAS</p>
        <Form
          form={form_Tickets_picker}
          id='form_Tickets_picker'
          onFinish={handleCalendar}  
          style={{marginTop: '40px'}}
        >
          <Form.Item 
            name='dateFrom'
            className="mobilMovementsFiltersTickets_datePicker">
              <DatePicker
                inputReadOnly
                style={{ width: '100%'}}
                placeholder='Fecha desde'
                onChange={(e)=>{
                  setDates(e)
                  form_Tickets_picker.setFieldsValue({
                    dateUntil: undefined  
                  });
                }}
              />
          </Form.Item>  
          <Form.Item 
            name='dateUntil'
            className="mobilMovementsFiltersTickets_datePicker">
              <DatePicker
                placeholder='Fecha hasta'
                inputReadOnly
                style={{ width: '100%'}}
                disabled={dates ? false : true}
                disabledDate={disabledDate}
              />
          </Form.Item>    
          <Button
            type='primary'
            key="submit" 
            form='form_picker'
            onClick={handleCalendar}
            style={{width: '100%'}}
          >
            Aplicar
          </Button>      
        </Form>                  
      </div>             
    </div>
  )
}

export default Panel_MovementsFiltersTickets;

