import {useEffect} from 'react'
import { BrowserRouter,  Routes } from 'react-router-dom';
import { UserContextProvider } from './Context/UserContext/UsersContext';
import MainRutes from './Routes/Mainrutes/MainRutes';

import './App.css';
import './assets/global.scss'
import { BodyContext, BodyContextProvider } from './Context/BodyContext.tsx/BodyContext';
import { TransactionsContextProvider } from './Context/TransactionsContext.tsx/TransactionsContext';
import {  MobileContextProvider } from './Context/MobileContext/MobileContext';


const App = () => {

  return (
    <BrowserRouter>
     <UserContextProvider>
      <BodyContextProvider>
        <TransactionsContextProvider>
          <MobileContextProvider>
            <MainRutes/>  
          </MobileContextProvider>
        </TransactionsContextProvider>
      </BodyContextProvider>
     </UserContextProvider>
    </BrowserRouter>
  );
}

export default App;


