import React, {useContext, useEffect} from 'react'
import { Routes , Route, Navigate, } from 'react-router-dom';
import { UserContext } from '../../Context/UserContext/UsersContext';
import AccessPage from '../../Pages/AccessPage/AccessPage';
import { Dashboard } from '../../Pages/Dashboard/Dashboard';
import { PrivateRoutes } from '../PrivateRoutes';
import { PublicRoutes } from '../PublicRoutes';


const MainRutes = () => {

    const {
      caballoNegroToken
    } = useContext(UserContext)

  return (
    <Routes>
      {caballoNegroToken && caballoNegroToken !== 'undefined'
        ? <Route path="/*" element={<PrivateRoutes />} />
        : <Route path="/*" element={<PublicRoutes />} />
      }
      <Route path='*' element={<Navigate to='/login' replace />} />
    </Routes> 
  );
}

export default MainRutes;

