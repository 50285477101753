import React, {useContext, useEffect, useState} from 'react';
import { UserContext } from '../../Context/UserContext/UsersContext';
import { Select } from 'antd';
import { useGetListSubAgencies, Record } from '../../Services/useGetListSubAgencies';
import './styles.scss'
import { BodyContext, Navbar as NavbarType } from '../../Context/BodyContext.tsx/BodyContext';
import { usePerDayReport } from '../../Services/usePerDayReport';
import { useTransactionsPerDAy } from '../../Services/useTransactionsPerDay';
import { TransactionsContext } from '../../Context/TransactionsContext.tsx/TransactionsContext';
import { SpinerHorizontal } from '../SpinerHorizontal/SpinerHorizontal';
import { useListAgencies } from '../../Services/useListAgencies'; 
import { useGetlistsAgencies } from '../../Services/useGetlistsAgencies';

interface NavbarProps {
    changeNavbar: NavbarType,
    setChangeNavbar: Function
}

export const Navbar: React.FC<NavbarProps> =({changeNavbar, setChangeNavbar})=>{
    const {
        caballoNegroToken,
        setListSubAgencies,
        listSubAgencies,
        setSubAgencyData,
        userData,
        subAgencydata,
        selectedAgency, 
        setSelectedAgency
    } = useContext(UserContext)

    const { 
        setNewDataTickets,
        setNewDataPerDay,
        setLoadingBody, 
        loadingBody,
        setLayerBody,
        setTypeMovement,
        setSeeAllTickets
    } = useContext(BodyContext)

    const {
        setDataOfAllTransactions, 
        setDataGroupedOfTransactions,
        setTransactionsLoading,
        transactionsLoading,
        setDateAndHoursAllTransactions,
    } = useContext(TransactionsContext)

    const {data: record} = useGetListSubAgencies(caballoNegroToken)
    const {data: listAgencies} = useGetlistsAgencies(caballoNegroToken)
    const {filterByDatePerDate } = usePerDayReport()
    const {filterTransactionsPerDay} = useTransactionsPerDAy()
    const {getListAgencias} = useListAgencies()
    const { Option } = Select;
    
    const [loadingSubAgencias, setLoadingSubAgencias] = useState<boolean>(false)
         
     
    useEffect(()=>{
        if(record && userData?.nivel_agencia == '0'){
            setListSubAgencies(record)            
        }
    },[record])


    const handleChange = (event: string)=>{
        setChangeNavbar('movements')
        setLayerBody('movements')
        setTypeMovement('per_day') 
        setSeeAllTickets(false)

        const date = new Date();
        const currentMonth =  date.getMonth() +1 
        const year = date.getFullYear()
        const perDay_StartDate= `${year}/${currentMonth}/01`
        const perDay_endDate= currentMonth == 12 ? `${year+1}/01/01` : `${year}/${currentMonth +1}/01`

        const selectedSubagency = listSubAgencies.filter((x)=> x.id_agencia == event)[0]
        setSubAgencyData(selectedSubagency)

       //Por defecto consulto las fechas para Movimientos 'por día'
       setLoadingBody({
        ...loadingBody,
        perDayReport: true
        }) 
        setNewDataPerDay(undefined)
        filterByDatePerDate(perDay_StartDate, perDay_endDate, selectedSubagency.id_agencia)

        //Por defecto consulto las fechas para Transacciones 'por día'
        setDataGroupedOfTransactions(undefined)
        setTransactionsLoading({
            ...transactionsLoading,
            getTransactionsPerDay: true              
        })
        filterTransactionsPerDay(perDay_StartDate,'00:00:00', perDay_endDate, '00:00:00', selectedSubagency.id_agencia)

        setNewDataTickets(undefined); //limpio datos de 'Movimientos' 'tickets
        setDataOfAllTransactions(undefined) //limpio datos de 'Transacciones' 'transacciones
        setDateAndHoursAllTransactions(undefined)
    
        // if(startDateTickets && endDateTickets){
        //     setNewDataTickets(undefined)
        //     setTicketsLoading({
        //         ...ticketsLoading,
        //         loading: true,
        //       }) 
        //     filterByDate(startDateTickets, endDateTickets,  selectedSubagency.id_agencia)
        // } 
 
        // if(dateAndHoursAllTransactions){
        //     setDataOfAllTransactions(undefined)
        //     setTransactionsLoading({
        //         ...transactionsLoading,
        //         getTransactionsPerDay: true              
        //     })
        //     const DyH = dateAndHoursAllTransactions
        //     filterAllTransactions(DyH.firstDate, DyH.firstHours, DyH.secondDate, DyH.secondHours, selectedSubagency.id_agencia) 
        // }      
                       
    }

    const callback = () =>{
        setLoadingSubAgencias(false)
    }
    const handleChangeAgencie = (id: string) =>{
        setLoadingSubAgencias(true)
        const selectedAgency = listAgencies?.filter((x)=> x.id_agencia == id)[0]
        setSelectedAgency(selectedAgency)
        setSubAgencyData(undefined)
        getListAgencias(id, callback)              
    }

    const Selects: React.FC = () =>{
        switch(userData?.nivel_agencia){
            case '0':
                return <>
                    {record && record.length > 0 ?

                        <Select
                            showArrow
                            style={{ width: '200px' }}
                            onChange={(e)=>handleChange(e)}
                            placeholder="Subagencia"
                            defaultValue= {subAgencydata?.descripcion}
                        >
                            { record.map((agency)=> (
                                <Option value={agency.id_agencia} key={agency.descripcion}>
                                    {agency.descripcion}
                                </Option>
                            ))}
                        </Select>
                        :  userData?.agencia_nombre 
                            ? <div><SpinerHorizontal info={'Cargando agencias'} fontSize='12px'/></div>
                            :<div></div>
                }
                </>               
            case '1':
                return <></>;
                
            case '3':
                return <>
                    {   listAgencies && listAgencies.length > 0 ?
                            <Select
                                showArrow
                                style={{ width: '200px' }}
                                onChange={(e)=>handleChangeAgencie(e)}
                                placeholder="Agencia"
                                defaultValue={selectedAgency?.descripcion}
                            >
                                { listAgencies.map((agency: Record)=> (
                                    <Option value={agency.id_agencia} key={agency.descripcion}>
                                        {agency.descripcion}
                                    </Option>
                                ))}
                            </Select>
                            :  userData?.agencia_nombre 
                                ? <div><SpinerHorizontal info={'Cargando agencias'} fontSize='12px'/></div>
                                :<div></div>
                    }
                    {
                        listSubAgencies && listSubAgencies.length > 0 && !loadingSubAgencias &&
                   
                            <Select
                                showArrow
                                style={{ width: '200px', marginLeft: '10px' }}
                                onChange={handleChange}
                                placeholder="Subagencia"
                                defaultValue= {subAgencydata?.descripcion}
                            >
                                { listSubAgencies.map((agency: Record)=> (
                                    <Option value={agency.id_agencia} key={agency.descripcion}>
                                        {agency.descripcion}
                                    </Option>
                                ))}
                            </Select>
                                             
                               
                    }
                    {
                        loadingSubAgencias && <div><SpinerHorizontal info={'Cargando subagencias'} fontSize='12px'/></div>
                    }
                </>;
            default:
                return <></>;           
        }
    }

    return(
        <div className="navbar_container">
            <div className="navbar_row">
            {
                userData && userData.id_agencia
                ? <>
                    <p>{userData?.agencia_nombre}</p>
                        <Selects/> 
                </>
                : <SpinerHorizontal info={'Cargando'} fontSize='12px'/>
            }
                
            </div>
        </div>
    )
}

export default Navbar;