import { Navigate, Route, Routes } from 'react-router-dom';
import AccessPage from '../../Pages/AccessPage/AccessPage';

export const PublicRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<AccessPage/>} /> 
            <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
    );
};




