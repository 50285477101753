import React, {useState, useEffect} from 'react'

export const useWindowSize =()=>{
    
  const [ancho, setAncho] = useState(window.innerWidth);
  const [alto, setAlto] = useState(window.innerHeight);

  const manejarCambioTamano = () => {
    setAncho(window.innerWidth);
    setAlto(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', manejarCambioTamano);

    return () => {
      window.removeEventListener('resize', manejarCambioTamano);
    };
  }, []);
  
  return{ancho, alto}
}