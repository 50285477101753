import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../Context/UserContext/UsersContext';
import './styles.scss'
import Table, { ColumnsType } from 'antd/es/table';
import { TransactionsContext } from '../../Context/TransactionsContext.tsx/TransactionsContext';
import { SpinerHorizontal } from '../../Components/SpinerHorizontal/SpinerHorizontal';
import { TotalBalance_transactions } from '../TotalBalance_transactions/TotalBalance_transactions';

interface RowData {
    credit_total: string,
    debe_total: string,
    fecha: string,
    cantidad_tickets: string,
    saldo: string,
}

export const Screen_TransactionsPerDate =()=>{

    const { subAgencydata, userData} = useContext(UserContext)
    const {
        dataGroupedOfTransactions, 
        dateAndHours,
        dataOfAllTransactions,
        transactionsLoading
    } = useContext(TransactionsContext)   
      
    const[idAccordingToLevel, setIdAccordingToLeave] =useState<string | undefined>(userData?.nivel_agencia == '1' ? userData.id_agencia : subAgencydata?.id_agencia)

    const moneda = userData?.nivel_agencia == '1' ? userData.moneda_resum : subAgencydata?.moneda_resum

    useEffect(()=>{
        if(idAccordingToLevel !== subAgencydata?.id_agencia){
            setIdAccordingToLeave(subAgencydata?.id_agencia)
        }
    },[dataGroupedOfTransactions, dataOfAllTransactions])

   
   
   const rowData= ()=>{
        let data: RowData[]=[];
        let moneda = userData?.nivel_agencia == '1' ? userData.moneda_resum : subAgencydata?.moneda_resum
       
        dataGroupedOfTransactions?.map((dataGroupedOfTransaction)=>{
            
           // let totalSaldo = Intl.NumberFormat("de-DE").format((parseFloat(newDataTicket.total_apuesta) - parseFloat(newDataTicket.total_devolucion) - parseFloat(newDataTicket.total_ganado))) + ' ' + moneda
            data.push({
                credit_total: Intl.NumberFormat("de-DE").format(Number(dataGroupedOfTransaction.credit_total)) + ' ' + moneda,
                debe_total: Intl.NumberFormat("de-DE").format(Number(dataGroupedOfTransaction.debe_total)) + ' ' + moneda,
                fecha: dataGroupedOfTransaction.fecha,
                cantidad_tickets: dataGroupedOfTransaction.cantidad_tickets,
                saldo: Intl.NumberFormat("de-DE").format(dataGroupedOfTransaction.saldo) + ' ' + moneda,
            })
        })
        return data
    }

   const handleRenderer = (text: string, event: RowData,  ) =>{
        return<a>{text}</a>
    }
  
   const columns: ColumnsType<RowData> = [
      {
        title: 'Fecha',
        dataIndex: 'fecha',
        key: 'fecha',
        render: handleRenderer,
    },
    {
        title: 'Toatl Tickets',
        dataIndex: 'cantidad_tickets',
        key: 'cantidad_tickets',
        render: handleRenderer,
    },
    {
        title: 'Credito',
        dataIndex: 'credit_total',
        key: 'credit_total',
        render: handleRenderer,
    },
    {
        title: 'Débito',
        dataIndex: 'debe_total',
        key: 'debe_total',
        render: handleRenderer,
    },  
    {
        title: 'Total',
        dataIndex: 'saldo',
        key: 'saldo',
        render: (text)=><a style={{color:'orange'}}>{text}</a>,
    },    
];
  
    return(
        <div className='transactionsPerDateMobile_containers'>
           {moneda && dataGroupedOfTransactions && dataGroupedOfTransactions.length !== 0 &&  dateAndHours &&
                <>
                    <p className='p_gmt'>Zona horaria: GMT-3.</p>
                    <TotalBalance_transactions
                        moneda={moneda} 
                        startDateTransactions= {dateAndHours.firstDate + ' ' + dateAndHours.firstHours}
                        endDateTransactions={dateAndHours.secondDate + ' ' + dateAndHours.secondHours}
                        type={'perDay'}
                    />
                </>
            }        
            { dataGroupedOfTransactions && dataGroupedOfTransactions.length !== 0 &&
                <Table 
                    scroll={{ x: 1000 }}
                    columns={columns} 
                    dataSource={rowData()}  
                />               
            }

            {transactionsLoading.getTransactionsPerDay &&  <SpinerHorizontal info={'Loading'}/>}
        </div>
    )
}

   
