import React, {useContext, useEffect, useState, useTransition} from 'react';
import { Form, Button, DatePicker, Select,  Tag,  Input, TimePicker, DatePickerProps, Row, Col } from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import './styles.scss'
import { useTransactionsPerDAy } from '../../Services/useTransactionsPerDay';
import { TransactionsContext } from '../../Context/TransactionsContext.tsx/TransactionsContext';
import moment, { Moment } from 'moment';
import { useAllTransactions } from '../../Services/useAllTransactions';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/es'; // Importa el idioma español si lo necesitas
import { useSearchTransactions } from '../../Services/useSearchTransaction';
import { useWindowSize } from '../../hooks/useWindowSize';
import { MobileContext } from '../../Context/MobileContext/MobileContext';
import { ScreenData } from '../HamburgerMenu/types';

interface filterInformation{
    first_date: Dayjs,
    first_hours: Dayjs,
    second_date: Dayjs,
    second_hours: Dayjs
  }
const tagRender = (props: CustomTagProps) => {

    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };    

    return (
      <Tag
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };  

  export interface NotificationFilters {
    colors: string[] | undefined,
    datePicker: Date[] | undefined,
    machines:  number[] | undefined,
    organizations: number[]| undefined,
  }

  export interface TransactionsRangePicker_props{
    selectedScreen: ScreenData
  }
export const Panel_TransactionsRangePicker: React.FC<TransactionsRangePicker_props> = ({
  selectedScreen
})=>{

  const { 
    dateAndHours, 
    setTransactionsLoading, 
    transactionsLoading, 
    setDataGroupedOfTransactions,
    typeTransactions,
    setDataOfAllTransactions
  } = useContext(TransactionsContext)
  const {controllerNavbarBottom, setControllerNavbarBottom} = useContext(MobileContext)
  const {filterTransactionsPerDay} = useTransactionsPerDAy()
  const {searchTransactionsServise} = useSearchTransactions()
  const {filterAllTransactions} = useAllTransactions()

  const [form_transactions] = Form.useForm();  
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  
  useEffect(()=>{
    form_transactions.resetFields()
  },[typeTransactions])

  useEffect(()=>{
    if(dateAndHours){
      filterTransactionsPerDay(dateAndHours.firstDate, dateAndHours.firstHours, dateAndHours.secondDate, dateAndHours.secondHours)
    }
  },[])

  const handleTransactionsFilter = async(e: any) =>{
    setTransactionsLoading({
      ...transactionsLoading,
      getTransactionsPerDay: true              
    })
    let first_date = e.first_date.format('YYYY/M/DD')
    let first_hours = e.first_hours.format('HH:mm:ss')
    let second_date = e.second_date.format('YYYY/M/DD')
    let second_hours = e.second_hours.format('HH:mm:ss')
    if(selectedScreen.name === 'transactions'){
      setDataOfAllTransactions(undefined)
      await filterAllTransactions(first_date, first_hours, second_date, second_hours)
    }else{
      setDataGroupedOfTransactions(undefined)
      await filterTransactionsPerDay(first_date, first_hours, second_date, second_hours)
    }
   
    setControllerNavbarBottom(
      {   
        movementsPerDayRangePicker: false,
        movementsTicketsRangePicker: false,
        searchTicket: false,
        showTickets: false,
        transactionsPerDayRangePicker: false,
        transactionsRangePicker: false,
        transactions: false,
        showTransactions: false}
    )       
  }


  const handleTransactionsFilter2 = async(e: any) =>{
    typeTransactions === 'perDay' && setDataGroupedOfTransactions(undefined);
    setTransactionsLoading({
      ...transactionsLoading,
      getTransactionsPerDay: true              
    })
    let first_date = e.first_date.format('YYYY/M/DD')
    let first_hours = e.first_hours.format('HH:mm:ss')
    let second_date = e.second_date.format('YYYY/M/DD')
    let second_hours = e.second_hours.format('HH:mm:ss')
    if(typeTransactions === 'perDay'){
      setDataGroupedOfTransactions(undefined)
      await filterTransactionsPerDay(first_date, first_hours, second_date, second_hours)
    }else{
      setDataOfAllTransactions(undefined)
      await filterAllTransactions(first_date, first_hours, second_date, second_hours)
    }   
  }




  const disabledEndDate = (current: Dayjs | null) => {
    if(selectedScreen.name === 'transactions'){ 
      if (startDate && current) {
        const minDate = dayjs(startDate).subtract(0, 'days');
        const maxDate = dayjs(startDate).add(3, 'days');
        return (current && (current < minDate || current > maxDate));
      }
      return true;
    }else{
      return false
    }
  };

  const handleChangePicker = (date: Dayjs | null) => {
    setStartDate(date);
    form_transactions.setFieldsValue({
      second_date: undefined
    });
  };


  return(
    <div className=  {`Panel_TransactionsRangePicker_container ${controllerNavbarBottom.transactionsPerDayRangePicker || controllerNavbarBottom.transactionsRangePicker ? 'visible' : 'hidden'}`}>
      <div className="Panel_TransactionsRangePicker_subContainers">
        <p><div className='Panel_TransactionsRangePicker_point'> </div> BUSQUEDA POR FECHAS</p>
        <Form
          id='form_transactions'
          form={form_transactions}
          onFinish={handleTransactionsFilter}  
        >
          <Form.Item 
            label='Fecha desde'
            name='first_date'
            className="Panel_TransactionsRangePicker_datePicker"
            rules={[
              {
                required: true,
                message: 'Seleccionar fecha',
              },
            ]}>
              <DatePicker 
                inputReadOnly
                style={{ width: '100%'}}
                onChange={handleChangePicker}
                //disabledDate={disabledDate}
              />
          </Form.Item>
          <Form.Item 
            label='Hora desde'
            name='first_hours'          
            className="Panel_TransactionsRangePicker_datePicker"
            rules={[
              {
                required: true,
                message: 'Seleccionar hora',
              },
            ]}
            > 
              <TimePicker  
                inputReadOnly
                style={{ width: '100%'}}/>
          </Form.Item>
          <Form.Item 
            name='second_date'
            label='Fecha hasta'
            className="Panel_TransactionsRangePicker_datePicker"
            rules={[
              {
                required: true,
                message: 'Seleccionar fecha',
              },
            ]}
            >
              <DatePicker
                style={{ width: '100%' }}
                disabledDate={disabledEndDate}
                inputReadOnly
                // onChange={handleEndDateChange}
              />
          </Form.Item>
          <Form.Item 
            name='second_hours'
            label='Hora hasta'
            className="Panel_TransactionsRangePicker_datePicker"
            rules={[
              {
                required: true,
                message: 'Seleccionar hora',
              },
            ]}
            >
              <TimePicker 
                style={{ width: '100%'}} 
                inputReadOnly
              />
          </Form.Item>          
          <Button
            form='form_transactions'
            type="primary"
            htmlType="submit"
            style={{width: '100%'}}
          >
            Aplicar
          </Button>                 
        </Form>   
      </div>   
    </div>
  )
}



