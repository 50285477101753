import {useContext, useState} from 'react';
import StartupBackground from '../../Components/StartupBackground/StartupBackground';
import Login from '../../Components/Login/Login';
import { UserContext } from '../../Context/UserContext/UsersContext';
import AnimationLoading from '../../Components/Spiner/AnimationLoading';

import './accessPage.scss';

export const AccessPage = ()=> {

  const {setChangeAccesPageForm, changeAccessPageForm, loading} = useContext(UserContext)
    
  return (
      <div className='accessPages-container'> 
        {loading && <AnimationLoading/>}
        {/* <StartupBackground/> */}
       
        <div className='accessPages-containerLogin' >
            {changeAccessPageForm === 'login' && <Login/> }
        </div>  
      </div>
    
  );
}

export default AccessPage;