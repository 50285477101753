import  React, { useContext, useEffect, useRef, useState } from "react";
import { BodyContext } from "../../Context/BodyContext.tsx/BodyContext";
import { Button, Modal } from "antd";
import './styles.scss'
import {ModalTicketsProps} from './types';
import { TicketsReport } from "../MovementsTickets/MovementsTickets";
import { TransactionsContext } from "../../Context/TransactionsContext.tsx/TransactionsContext";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const ModalTickets: React.FC<ModalTicketsProps> =({
    isModalOpen,
    setIsModalOpen,
    handleCancel,
    moneda,
    selectedTicketId,
    setSelectedTicketId,
    setShowAllTransactions
})=>{

    const detailAndCopy = useRef<any>(null);

    const {
        ticketDetail,
    } = useContext(BodyContext)

    const { 
        detailDataTransaction
    } = useContext(TransactionsContext)

    const [ticketData, setTicketData] = useState<TicketsReport>()
    const [loadingDownloadPDF, setLoadingDownloadPDF] = useState(false)

    useEffect(()=>{
        const ticket = detailDataTransaction?.filter((item)=> item.num_ticket === selectedTicketId)
        setTicketData(ticket?.[0])
    }, [])

    const handleGeneratePDF = () => {
        const input = detailAndCopy.current;
        html2canvas(input)
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'pt',  [316.8, 480.9]);
            const imgWidth = pdf.internal.pageSize.getWidth();
            const imgHeight = (canvas.height * imgWidth) / canvas.width; // Calcula la altura proporcional
            const x = (imgWidth - 281.6  ) / 2
            const y = (imgHeight - 427.2  ) / 2; // Centra verticalmente
        
            pdf.addImage(imgData, 'PNG', x, y, 281.6, 427.2);
            pdf.save('Detalle_transaccion.pdf');
        });
               
    };

    // const handleGeneratePDF = () => {
    //     const input = detailAndCopy.current;
    //     html2canvas(input)
    //     .then((canvas) => {
    //         const imgData = canvas.toDataURL('image/png');
    //         const pdf = new jsPDF('p', 'pt', 'a4'); // Cambiado a tamaño de papel A4
    //         const imgWidth = pdf.internal.pageSize.getWidth();
    //         const imgHeight = (canvas.height * imgWidth) / canvas.width;
    //         const x = 10; // Margen izquierdo
    //         const y = 10; // Margen superior
            
    //         pdf.addImage(imgData, 'PNG', x, y, imgWidth - 20, imgHeight - 20); // Ajustado para ocupar todo el ancho y alto disponible
    //         pdf.save('Detalle_transaccion.pdf');
    //     });
    // };

    const handleOkDetailTicket = () => {
        handleGeneratePDF()
        setLoadingDownloadPDF(false)
        setIsModalOpen(false);
        setSelectedTicketId(undefined)
        if(!!setShowAllTransactions){
            setShowAllTransactions(true)   
        } 
    };
 
    return(
        <Modal  
            style={{marginTop: '-50px'}}
            open={isModalOpen} 
            onOk={handleCancel} 
            onCancel={handleCancel}
            width={400}
            footer={[
                <Button key="back" onClick={handleCancel}>
                  Cerrar
                </Button>,
                <Button 
                    key="submit" 
                    type="primary" 
                    loading={loadingDownloadPDF} 
                    onClick={handleOkDetailTicket}>
                  Descargar PDF
                </Button>,
              ]}
            >
            <div style={{display: 'flex', flexDirection:'column', alignItems:'center'}} ref={detailAndCopy} >
                <h3 style={{color: 'orange', margin: '10px 0px'}}>DETALLE APUESTA</h3>
                <div className='movimentsTickets_p-title_transactions'>Ticket: 
                    <p className='movimentsTickets_p-data_transactions'>{ticketData?.num_ticket} - {ticketData?.num_verificador}</p> 
                </div>
                <div className='movimentsTickets_p-title_transactions'>Fecha Ticket: 
                    <p className='movimentsTickets_p-data_transactions'>
                        {ticketData?.fecha_ticket}hs
                    </p>
                </div>
                <div className='movimentsTickets_p-title_transactions'>Hipódromo: 
                    <p className='movimentsTickets_p-data_transactions'> {ticketData?.desc_hipodromo}</p>
                </div>
                <div className='movimentsTickets_p-title_transactions'>Fecha Carrera: 
                    <p className='movimentsTickets_p-data_transactions'> {ticketData?.fecha}</p>
                </div>
                <div className='movimentsTickets_p-title_transactions'>Número de carrera: 
                    <p className='movimentsTickets_p-data_transactions'> {ticketData?.num_carrera}</p>
                </div>
                <div className='movimentsTickets_p-title_transactions'>Usuario: 
                    <p className='movimentsTickets_p-data_transactions'> {ticketData?.num_usuario}</p>
                </div>
                <div className='movimentsTickets_p-title_transactions'>Total apuesta: 
                    <p className='movimentsTickets_p-data_transactions'> {ticketData?.total_apuesta}</p>
                </div>
                <div className='movimentsTickets_p-title_transactions'>Total devuelto: 
                    <p className='movimentsTickets_p-data_transactions'> {ticketData?.total_devolucion}</p>
                </div>
                <div className='movimentsTickets_p-title_transactions'>Total ganado: 
                    <p className='movimentsTickets_p-data_transactions'> {ticketData?.total_ganado}</p>
                </div>

                <h3 style={{color: 'orange', marginTop:'30px', marginBottom:'5px'}}>COPIA BOLETO</h3>
                <div className='movimentsTickets_p-title_transactions'>
                    <p className='movimentsTickets_p-data_transactions'>{ticketDetail?.descripcion}</p>
                </div>
                <p className='movimentsTickets_p-data_transactions' style={{marginLeft:'0px'}}>{ticketDetail?.vales}v({ticketDetail?.combinaciones}) {Number(ticketDetail?.importe_vale).toFixed(2)} {moneda}</p>
                <p className='movimentsTickets_p-data_transactions' style={{marginLeft:'0px'}}>
                    
                {ticketDetail?.caballo_1}
                {ticketDetail?.caballo_2 === '' ? '' : ', ' }{ticketDetail?.caballo_2}
                {ticketDetail?.caballo_3 === '' ? '' : ', ' }{ticketDetail?.caballo_3}
                {ticketDetail?.caballo_4 === '' ? '' : ', ' }{ticketDetail?.caballo_4}
                {ticketDetail?.caballo_5 === '' ? '' : ', ' }{ticketDetail?.caballo_5}
                {ticketDetail?.caballo_6}
                
                </p>
                <div className='movimentsTickets_p-title_transactions'>Total:
                    <p className='movimentsTickets_p-data_transactions'> {Number(ticketDetail?.importe).toFixed(2)} {moneda}</p>                  
                </div>
                
                <div className='movimentsTickets_p-title_transactions'>Ticket 
                    <p className='movimentsTickets_p-data_transactions'> {ticketData?.num_ticket} - {ticketData?.num_verificador}</p>
                </div>
            </div>
        </Modal>
    )
}