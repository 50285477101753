import React, {useContext, useEffect, useState} from 'react';
import { Form, Button, DatePicker, Select,  Tag,  Input, Row, Col } from 'antd';
import { BodyContext } from '../../Context/BodyContext.tsx/BodyContext';
import { useTicketsReport } from '../../Services/useTicketsReport';
import './styles.scss'
import { usePerDayReport } from '../../Services/usePerDayReport';
import { useSearchTicket } from '../../Services/useSearchTicket';
import type { Dayjs } from 'dayjs';
import { useWindowSize } from '../../hooks/useWindowSize';
import { MobileContext } from '../../Context/MobileContext/MobileContext';
import { useNavbarBottom } from '../NavbarBottom/useNavbarBottom';

import dayjs from 'dayjs';
import type { RangePickerProps } from 'antd/es/date-picker';

type RangeValue = [Dayjs | null, Dayjs | null] | null;

export const Panel_MovementFiltersPerDay: React.FC =()=>{
  const [dates, setDates] = useState<Dayjs | null>(null);
  const [value, setValue] = useState<RangeValue>(null);

  const {
    setTicketsLoading,
    ticketsLoading,
    setNewDataTickets,
    setSearchTicketsNull,
    setNewDataPerDay,
    setLoadingBody,
    loadingBody,
  } = useContext(BodyContext)

  const {
    controllerNavbarBottom,
  } = useContext(MobileContext)

  const {filterByDatePerDate } = usePerDayReport()
 
  const [formPicker] = Form.useForm();
  const [formData, setFormData] = useState<string>('')

  const {searchTicket} = useSearchTicket()  
  const {
    onClick_navbarBottom
  } = useNavbarBottom()
 
  const handleCalendar = async()=>{
    
      setNewDataPerDay(undefined);
      setLoadingBody({
        ...loadingBody,
        perDayReport: true
      }) 
      const dateFrom = formPicker.getFieldValue('dateFrom')
      const dateUntil = formPicker.getFieldValue('dateUntil')
      const startDate = dateFrom.format('YYYY/M/DD')
      const dateEnd = dateUntil.format('YYYY/M/DD')
      await filterByDatePerDate(startDate, dateEnd)
      formPicker.resetFields()  
      onClick_navbarBottom('movementsPerDayRangePicker')
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    if (!dates) {
      return true;
    }
     return current.isBefore(dates, 'day') || current.isAfter(dates.add(2, 'days'), 'day');
  };

  return(
    <div className= {`movementsFiltersMobile_container ${controllerNavbarBottom.movementsPerDayRangePicker  ? 'visible' : 'hidden'}`} >
      <div className='movementsFiltersMobile_subContainers'>
        <p><div className='movementsFiltersMobile_point'> </div> BUSQUEDA POR FECHAS</p>
          <Form
            form={formPicker}
            id='form_picker'
            onFinish={handleCalendar}  
            style={{marginTop:'50px'}}
          >         
            <Form.Item 
              name='dateFrom'
              className="movementsFiltersMobile_datePicker">
                 <DatePicker
                  inputReadOnly
                  style={{ width: '100%'}}
                  placeholder='Fecha desde'
                  onChange={(e)=>setDates(e)}
                />
            </Form.Item>
            <Form.Item 
            name='dateUntil'
            className="movementsFiltersMobile_datePicker">
              <DatePicker
                placeholder='Fecha hasta'
                inputReadOnly
                style={{ width: '100%'}}
              />
          </Form.Item>    
            <Button
              type='primary'
              key="submit" 
              form='form_picker'
              onClick={handleCalendar}
              style={{width: '100%'}}
            >
              Aplicar
            </Button>
        </Form>          
      </div>          
    </div>
  )
}

export default Panel_MovementFiltersPerDay;

