import React from 'react';
import  Logo  from '../../assets/images/LogoHorizontalConTransparenciaAmarillo.png'
import Row from  'antd';
import { SettingOutlined } from '@ant-design/icons';
import './styles.scss'

export const Configurations: React.FC =()=>{

    return(
        <div className="configurations_container">
            <div className='configurations_title_container'>
                <SettingOutlined style={{ fontSize: '18px', color: 'white', marginLeft: '20px'}} />    
                <p>Configuración</p>
            </div>
            <div className='configurations_body_container'></div>
        </div>
    )
}

export default Configurations;

