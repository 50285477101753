import './animationLoading.scss'

export  const BackgroundLoading =()=>{
    return(
        <div className="background-loading">
        </div>
      
    )
}

export default BackgroundLoading;