import React, {useContext, useEffect, useState} from 'react';
import { SwapOutlined, DownOutlined, EyeOutlined,EyeInvisibleOutlined } from '@ant-design/icons';
import { UserContext } from '../../Context/UserContext/UsersContext';
import { Button, Tooltip, notification } from 'antd';
import MovementsFilters from '../MovementsFilters/MovementsFilters';
import { MovementsTickets } from '../MovementsTickets/MovementsTickets';

import './styles.scss'
import { useUser } from '../../Services/useUser';
import { BodyContext } from '../../Context/BodyContext.tsx/BodyContext';
import { MovementsPerDay } from '../MovementsPerDay/MovementsPerDay';
import { usePerDayReport } from '../../Services/usePerDayReport';
import { useWindowSize } from '../../hooks/useWindowSize';
import ExportCSV from '../MovementsTickets/ExportCSV';

type AgencyMoviments = {}

const AgencyMoviments: React.FC<AgencyMoviments> = () =>{
    const { typeMovements} = useContext(BodyContext)

    return(
        <div>
            {typeMovements === 'tickets' && <MovementsTickets/>}
            {typeMovements === 'per_day' && <MovementsPerDay/>}
        </div>
    )
}

export const Movements: React.FC =()=>{  
    const { subAgencydata, userData } = useContext(UserContext)
    const { 
        typeMovements, 
        setTypeMovement, 
        startDatePerDay, 
        endDatePerDay, 
        setNewDataPerDay, 
        setLoadingBody, 
        loadingBody, 
        showFilters,
        setShowFilters,
        newDataTickets,
        seeAllTickets
    } = useContext(BodyContext)
    const {filterByDatePerDate } = usePerDayReport()
    const { alto, ancho } = useWindowSize()

    const handleUploadData = async()=>{
        if(startDatePerDay && endDatePerDay){
            setNewDataPerDay(undefined);
            setLoadingBody({
                ...loadingBody,
                perDayReport: true
            }) 
            await filterByDatePerDate(startDatePerDay, endDatePerDay)
        }else{
            notification.info({ 
                message:'Aviso',
                description:
                  'No hay fechas cargadas', 
                placement: 'topRight'
            })
        }      
    }
    
    return(
        <div className="movements_container">
            <div className='movements_title_container'>
                <div className='movements_title_left'>
                    <SwapOutlined style={{ fontSize: '18px', color: 'white', marginLeft: '20px'}} />    
                    <p>Movimientos</p>
                    <div className="movements_btnContainers">
                        <Button
                            type="ghost"
                            className="movements_tabButton"
                            htmlType="submit"
                            onClick={()=>{
                                setTypeMovement('per_day')
                            }}
                            style={typeMovements ==='per_day' ? {borderBottom: '2px solid #1677ff', background: '#23272e', borderRadius: '3px'}: {borderBottom: ''} }
                        >
                            Por día
                        </Button>
                        <Button
                            type="ghost"
                            className="movements_tabButton"
                            htmlType="submit"
                            onClick={()=>{
                                setTypeMovement('tickets')
                            }}
                            style={typeMovements ==='tickets' ? {borderBottom: '2px solid #1677ff', background: '#23272e', borderRadius: '3px'}: {} }
                        >
                            Tickets
                        </Button>
                    </div>
                </div>
                <div>
                    { typeMovements === 'per_day' &&
                    <Tooltip
                        placement="top" 
                        title='Los datos se actualizarán con las últimas fechas consultadas'
                    >
                        <Button
                            type="ghost"
                            className="movements_upload"
                            htmlType="submit"
                            onClick={handleUploadData}
                        >
                            Actualizar datos
                        </Button>
                    </Tooltip>
                    }
                    {
                        ancho < 1300 &&
                        <Button
                            type="ghost"
                            className="movements_upload"
                             onClick={()=>setShowFilters(!showFilters)}
                        >
                            Filtros { showFilters ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        </Button>
                    } 
                    {
                        newDataTickets && newDataTickets.length !== 0 &&  typeMovements === 'tickets' && seeAllTickets &&
                        <ExportCSV data={newDataTickets}/>
                    }                                     
                </div>
            </div>            
            <div className='movements_body_container'>
                <div className='movements_filters'>
                    {
                        userData && userData.nivel_agencia === '1' && showFilters &&
                        <MovementsFilters/>
                    }
                    {
                         userData && (userData.nivel_agencia === '0' || userData.nivel_agencia === '3') && showFilters &&
                            subAgencydata 
                            ? <MovementsFilters/>
                            : <div></div>
                    }
                </div>
                <div className={ typeMovements == 'per_day' ? 'movements_timeline scrollTrue' : 'movements_timeline'  }>
                    {
                        userData && userData.nivel_agencia === '1' &&
                        <AgencyMoviments/>
                    }
                    {
                        userData && (userData.nivel_agencia === '0' || userData.nivel_agencia === '3') ?
                            subAgencydata 
                                ? <AgencyMoviments/>
                                : <p style={ancho < 1300 ? {color: 'red', marginLeft:'20px'} :{color: 'red'}}>Seleccione una agencia</p>    
                            : <div></div>                    
                    }                   
                </div>    
            </div>             
        </div>
    )
}

export default Movements;

