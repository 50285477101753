import { Button, Col, Row } from "antd"
import React, {useContext, useState} from "react"
import './styles.scss'
import { BodyMobile, ControllerNavbarBottom, MobileContext } from "../../Context/MobileContext/MobileContext"
import {  message  } from 'antd';
import { 
    NavbarBottomProprs, 
 } from "./type";
import { useNavbarBottom } from "./useNavbarBottom";
import { BodyContext } from "../../Context/BodyContext.tsx/BodyContext";
import { TransactionsContext } from "../../Context/TransactionsContext.tsx/TransactionsContext";


export const NavbarBottom: React.FC<NavbarBottomProprs> = ({

}) =>{

    const {
        isVisibleState, 
        setIsVisible,
        showBodyMobile,
        controllerNavbarBottom,
        setControllerNavbarBottom
    } = useContext(MobileContext)

    const {newDataTickets} = useContext(BodyContext)
    const { 
        dataOfAllTransactions,
    } = useContext(TransactionsContext)

    const{
        onClick_navbarBottom,
        refresh_MovementPerDay,
        refresh_transactionsPerDay,
        contextHolder
    } = useNavbarBottom()

    const [messageEmptyTable, contextMessgeEmptyTable] = message.useMessage();

    const handleClick = (displayNavbar: string, idName: string) =>{
        switch(displayNavbar){
            case 'movementsPerDayRangePicker':
            case 'movementsTicketsRangePicker':
            case 'searchTicket':  
            case 'transactionsPerDayRangePicker': 
            case 'transactionsRangePicker':
            case 'transactions':      
                onClick_navbarBottom(displayNavbar)
                break;
            case 'showTickets': 
                if(newDataTickets){
                    onClick_navbarBottom(displayNavbar)
                }else{
                    messageEmptyTable.info('No hay datos para visualizar en tabla, haga una consulta.');
                }
            break;
            case 'showTransactions':
                if(dataOfAllTransactions){
                    onClick_navbarBottom(displayNavbar)
                }else{
                    messageEmptyTable.info('No hay datos para visualizar en tabla, haga una consulta.');
                }
                break;
            case 'refresh_MovementPerDay':
                refresh_MovementPerDay();
                break;
            case 'refresh_transactionsPerDay':
                refresh_transactionsPerDay()
                break
            default:
                break
        }
    }

    return(
        <>
            {contextHolder}
            {contextMessgeEmptyTable}
            <div className={`navbarBottom_container ${isVisibleState ? 'visible' : 'hidden'}`}>
                <Row style={{width: '100%'}}>
                    {showBodyMobile?.map((data: BodyMobile)=>{
                        return(
                        <Col 
                            span={6} 
                            style={{
                            display:'flex',
                            justifyContent:'center',
                            alignItems:' center'
                            }}
                        >
                            <Button
                            style={{  
                                width: '60px',
                                display:'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                backgroundColor: '#000120',
                                boxShadow: '2px 2px  #0c3e83',
                                marginRight:'5px',
                                border: 'none',
                                height: 'auto' }}
                                onClick={()=>handleClick(data.onClick, data.idName)}
                                className="navbarBottom_button"
                            >
                                <div className="navbarBottom_element_button">
                                    { controllerNavbarBottom[data.onClick as keyof ControllerNavbarBottom] ? data.iconActive : data.icon}
                                    {data.nameButton}
                                </div>                                
                            </Button>
                        </Col>)
                    })}
                </Row>           
            </div>
        </>     
    )
}

export default NavbarBottom