import React, {useContext, useEffect, useState} from 'react';
import { Form, Button, DatePicker, Select,  Tag,  Input, Row, Col } from 'antd';
import { BodyContext } from '../../Context/BodyContext.tsx/BodyContext';
import { useTicketsReport } from '../../Services/useTicketsReport';
import './styles.scss'
import { usePerDayReport } from '../../Services/usePerDayReport';
import { useSearchTicket } from '../../Services/useSearchTicket';
import type { Dayjs } from 'dayjs';
import { useWindowSize } from '../../hooks/useWindowSize';

type RangeValue = [Dayjs | null, Dayjs | null] | null;

export const MovementsFilters: React.FC =()=>{
  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>(null);

  const {
    typeMovements, 
    setTicketsLoading,
    ticketsLoading,
    setIsLoadingAllTickets,
    setNewDataTickets,
    setSearchTicketsNull,
    setNewDataPerDay,
    setLoadingBody,
    loadingBody,
    seeAllTickets, 
    setSeeAllTickets
  } = useContext(BodyContext)

  const {filterByDate} = useTicketsReport()
  const {filterByDatePerDate } = usePerDayReport()
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const { alto, ancho } = useWindowSize()

  const [form] = Form.useForm();
  const [formPicker] = Form.useForm();
  const [form_Tickets_picker] = Form.useForm();
  const [formData, setFormData] = useState<string>('')

  const [loadingSearch, setLoadingSearch] = useState(false)
  const {searchTicket} = useSearchTicket()  
 
  const handleSearch = async (event: any) =>{
    setSeeAllTickets(true)
    setSearchTicketsNull(undefined)
    setNewDataTickets(undefined)
    setTicketsLoading({
      ...ticketsLoading,
      loading: true,
    }) 
    await searchTicket(formData, 'shareTicket')
  }

const handleChange = (event: any)=>{
  event.preventDefault()
  const name = event.target.name 
  const value = event.target.value
  setFormData(value)
};
   

  const handleCalendar = async(event: any)=>{
    if(typeMovements == 'tickets'){
      setSearchTicketsNull(undefined)
      setTicketsLoading({
        ...ticketsLoading,
        loading: true,
      }) 
      const startDate = event[0].format('YYYY/M/DD')
      const dateEnd =  event[1].format('YYYY/M/DD')
      setNewDataTickets(undefined)
      await filterByDate(startDate, dateEnd)
      form_Tickets_picker.resetFields()
    }else{
      setNewDataPerDay(undefined);
      setLoadingBody({
        ...loadingBody,
        perDayReport: true
      }) 
      const startDate = event[0].format('YYYY/M/DD')
      const dateEnd =  event[1].format('YYYY/M/DD')
      await filterByDatePerDate(startDate, dateEnd)
      formPicker.setFieldsValue({
        datePicker: ''  
      }); 
    }       
  }

  const handleSeeAll =()=>{
    setSearchTicketsNull(undefined)
    setSeeAllTickets(!seeAllTickets)
    if(!seeAllTickets){
      setIsLoadingAllTickets(true)
      setTimeout(()=>{
        setIsLoadingAllTickets(false)
      },2000)
    }
  }

  const handleReset = ()=>{
    setNewDataTickets(undefined) 
    form.setFieldsValue({
      ticketsNumber: ''  
    });
  } 

   const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') >= 3;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 3;
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };


  return(
    <div className="movimentsFilters_container">
      {ancho < 1300 &&
        <>
         { typeMovements === 'per_day' &&
            <Form
              form={formPicker}
              onFinish={handleCalendar}  
              style={{margin: '0px', padding:'0px'}}
            >
            
                <Form.Item 
                  name='datePicker'
                  label='Fechas'
                  className="movimentsFilters_datePicker"
                  style={{margin: '0px'}}>
                    <RangePicker  
                    placeholder={['Desde', 'Hasta']}
                    onChange={handleCalendar}
                    />
                </Form.Item>
            </Form> 
          }
          {  typeMovements === 'tickets' &&
            <>
              <Form
                form={form_Tickets_picker}
                onFinish={handleCalendar}  
                style={{    height: '40px'}}
              >
                <Row style={{width: ancho > 820 ? '50%' : ' 70%'}}>
                  <Col span={14}>
                      <Form.Item 
                        name='datePicker'
                        label='Fechas'
                        style={{marginRight:'10px'}}
                        className="movimentsFilters_datePicker">
                          <RangePicker  
                            value={dates || value}
                            onCalendarChange={(val) => {
                              setDates(val);
                            }}
                            onChange={handleCalendar}
                            disabledDate={disabledDate}
                            onOpenChange={onOpenChange}
                            placeholder={['Desde', 'Hasta']}
                            inputReadOnly
                          />
                      </Form.Item>                 
                  </Col>
                  <Col span={10}>
                    <Button
                      type="ghost"
                      className="movimentsFilters_filtersBtn"
                      loading={loadingSearch}
                      onClick={handleSeeAll}
                    >
                      {seeAllTickets ? 'Ocultar tickets' : 'Ver tickets' }
                    </Button>
                  </Col>
                </Row>
              </Form>
              
                <Form
                  form={form}
                  style={{    height: '40px'}}
                > 
                  <Row style={{width: ancho > 820 ? '50%' : ' 70%'}}>
                    <Col span={14}>
                      <Form.Item
                        style={{color:'white', marginRight:'10px'}}
                        name="ticketsNumber"
                        className="movimentsFilters_selectColor"
                        label={'Buscar Ticket'}>
                        <Input
                          style={{backgroundColor: 'transparent', color: 'white', borderColor: 'white', }}
                          type='number'
                          placeholder='N° ticket'
                          onChange={handleChange}
                          value={formData}
                      />                
                      </Form.Item >
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        className='movimentsFilters_container-btn'   
                      >
                        <Button
                          type="ghost"
                          className="movimentsFilters_filtersBtn"
                          htmlType="submit"
                          loading={loadingSearch}
                          onClick={handleSearch}
                        >
                          Buscar
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Button
                            type="ghost"
                            className="movimentsFilters_filtersBtn"
                            loading={loadingSearch}
                            onClick={handleReset}
                          >
                            Cancelar
                        </Button>  
                    </Col>
                  </Row>                     
                </Form>
                 
            </>
          }
        </>
      }
      {ancho > 1300 &&
        <>
          <p><div className='movimentsFilters_point'> </div> FILTROS</p>
          {
            typeMovements === 'per_day' &&
          <Form
            form={formPicker}
            onFinish={handleCalendar}  
          >
          
              <Form.Item 
                name='datePicker'
                className="movimentsFilters_datePicker">
                  <RangePicker  
                    style={{ width: '100%'}}
                    onChange={handleCalendar}
                  />
              </Form.Item>
          </Form> 
          }
          {  typeMovements === 'tickets' &&
            <>
              <Form
                form={form_Tickets_picker}
                onFinish={handleCalendar}  
              >
                <Form.Item 
                  name='datePicker'
                  className="movimentsFilters_datePicker">
                    <RangePicker  
                      value={dates || value}
                      style={{ width: '100%'}}
                      onCalendarChange={(val) => {
                        setDates(val);
                      }}
                      onChange={handleCalendar}
                      disabledDate={disabledDate}
                      onOpenChange={onOpenChange}
                      //blurOnSelect 
                    />
                </Form.Item>
                  <Button
                    type="ghost"
                    className="movimentsFilters_filtersBtn width"
                    loading={loadingSearch}
                    onClick={handleSeeAll}
                  >
                    {seeAllTickets ? 'Ocultar tickets' : 'Ver tickets' }
                  </Button>
              </Form>

            <Form
              layout="vertical"
              form={form}
            > 
              <p style={{marginTop:'70px'  }}>Buscar Ticket</p>
              <Form.Item
                style={{color:'white'}}
                name="ticketsNumber"
                className="movimentsFilters_selectColor">
                <Input
                  style={{backgroundColor: 'transparent', color: 'white', borderColor: 'white'}}
                  type='number'
                  placeholder='N° ticket'
                  onChange={handleChange}
                  value={formData}
              />                
              </Form.Item >
              <Row  justify="center">
                <Col span={12}>
                  <Form.Item
                    className='movimentsFilters_container-btn'          
                  >
                    <Button
                      type="ghost"
                      className="movimentsFilters_filtersBtn"
                      htmlType="submit"
                      loading={loadingSearch}
                      onClick={handleSearch}
                    >
                      Buscar
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Button
                      type="ghost"
                      className="movimentsFilters_filtersBtn"
                      loading={loadingSearch}
                      onClick={handleReset}
                    >
                      Cancelar
                  </Button>
                </Col>
              
              </Row>
              
            </Form>
            </>
          }
        </>
      }
    </div>
  )
}

export default MovementsFilters;

