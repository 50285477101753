
import { BodyMobile, MobileContext } from '../../Context/MobileContext/MobileContext';
import { useContext } from 'react';
import { TransactionsContext } from '../../Context/TransactionsContext.tsx/TransactionsContext';


export type NumberToBodyMobile = {
    [key: number]: BodyMobile[];
  };



  export type Screen = {
    [key: number]: ScreenData;
  };

  export interface ScreenData{
    name: 'movementsPerDay' | 'movementsTickets' | 'transactionsPerDay' | 'transactions',
    label: string,
  }

  const movementsPerDay: ScreenData ={
    name: 'movementsPerDay',
    label: 'Movimientos / Por día'
  }

  const movementsTickets: ScreenData ={
    name: 'movementsTickets',
    label: 'Movimientos / Tickets'
  }

  const transactionsPerDay: ScreenData ={
    name: 'transactionsPerDay',
    label: 'Trasacciones / Por día'
  }

  const transactions: ScreenData ={
    name: 'transactions',
    label: 'Transacciones / Transacciones'
  }
  

  export const screenSelected: Screen = {
    1: movementsPerDay,
    2: movementsTickets,
    3: transactionsPerDay,
    4: transactions,
  }; 