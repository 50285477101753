import React, {
    createContext,
    useContext,
    useState,
  } from 'react';
import { TransactionsPerDay } from '../../Services/useTransactionsPerDay';
import { AllTransactions } from '../../Services/useAllTransactions';
import { dataXLSX } from '../../Components/ExportXLSXTransactions/type';
import { TicketsReport } from '../../Services/useGetAllTransactions';

  class VoidContext implements TransactionsContext {
    get transactionsLoading(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setTransactionsLoading(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get dateAndHours(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setDateAndHours(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get dataGroupedOfTransactions(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setDataGroupedOfTransactions(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get dataOfAllTransactions(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setDataOfAllTransactions(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get typeTransactions(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setTypeTransactions(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setDateAndHoursAllTransactions(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get dateAndHoursAllTransactions(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get searchTransactions(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setSearchTTransactions(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get filterValueUser(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setFilterValueUser(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get filterValueId(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setFilterValueId(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get tableTransactionsData(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setTableTransactionsData(): never {
      throw new Error('Cannot consume context outside of provider');
    }
     get detailDataTransaction(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setDetailDataTransaction(): never {
      throw new Error('Cannot consume context outside of provider');
    }
  }

  interface TransactionsContext {
    dataGroupedOfTransactions:TransactionsPerDay[] | undefined,
    setDataGroupedOfTransactions:Function,
    dataOfAllTransactions:AllTransactions[] | undefined,
    setDataOfAllTransactions: Function,
    typeTransactions: 'perDay' | 'allTransactions', 
    setTypeTransactions:Function,
    searchTransactions: AllTransactions | undefined,
    setSearchTTransactions: Function,
    transactionsLoading:{
      getTransactionsPerDay: boolean,
    },
    setTransactionsLoading: Function
    dateAndHours:{
      firstDate: string,
      firstHours: string,
      secondDate: string,
      secondHours: string
    } | undefined,
    setDateAndHours: Function
    dateAndHoursAllTransactions:{
      firstDate: string,
      firstHours: string,
      secondDate: string,
      secondHours: string
    } | undefined,
    setDateAndHoursAllTransactions: Function,
    filterValueUser: string[] | undefined,
    setFilterValueUser: Function,
    filterValueId: string[] | undefined,
    setFilterValueId: Function,
    tableTransactionsData:  any | undefined,
    setTableTransactionsData: Function,
    detailDataTransaction: TicketsReport[] | undefined,
    setDetailDataTransaction: Function
  }

  interface propsWithChildren {
    children: React.ReactNode
  }

  
  export const TransactionsContext = createContext<TransactionsContext>(new VoidContext());
  export const TransactionsContextProvider= ({ children }: propsWithChildren): JSX.Element => {

    const date = new Date();
    const currentMonth =  date.getMonth() +1 
    const year = date.getFullYear()
    const StartDate= `${year}/${currentMonth}/01`
    const endDate= currentMonth == 12 ? `${year+1}/01/01` : `${year}/${currentMonth +1}/01`


    const [dataGroupedOfTransactions, setDataGroupedOfTransactions] = useState<TransactionsPerDay[] | undefined>()
    const [dataOfAllTransactions, setDataOfAllTransactions] = useState<AllTransactions[] | undefined>()
    const [typeTransactions, setTypeTransactions] = useState<'perDay' | 'allTransactions'>('perDay')
    const [searchTransactions, setSearchTTransactions] = useState<AllTransactions | undefined >()
    const [filterValueUser, setFilterValueUser] = useState<string[] | undefined>()
    const [filterValueId, setFilterValueId] = useState<string[] | undefined>()
    const [tableTransactionsData, setTableTransactionsData] = useState<dataXLSX[]>()
    const [detailDataTransaction, setDetailDataTransaction] = useState<TicketsReport[] | undefined>()

    const [dateAndHoursAllTransactions, setDateAndHoursAllTransactions] = useState<{
      firstDate: string,
      firstHours: string,
      secondDate: string,
      secondHours: string
    } | undefined>()
    const [dateAndHours, setDateAndHours] = useState<{
      firstDate: string,
      firstHours: string,
      secondDate: string,
      secondHours: string
    } | undefined>({
      firstDate: StartDate,
      firstHours: '00:00:00',
      secondDate: endDate,
      secondHours: '00:00:00'
    })
    const [transactionsLoading, setTransactionsLoading]=useState<{
      getTransactionsPerDay: boolean,
    }>(
      {
        getTransactionsPerDay: true,
      }
    )
   
    return (
      <TransactionsContext.Provider value={{
        transactionsLoading, 
        setTransactionsLoading,
        dateAndHours, 
        setDateAndHours,
        dataGroupedOfTransactions, 
        setDataGroupedOfTransactions,
        dataOfAllTransactions, 
        setDataOfAllTransactions,
        typeTransactions, 
        setTypeTransactions,
        dateAndHoursAllTransactions, 
        setDateAndHoursAllTransactions,
        searchTransactions, 
        setSearchTTransactions,
        filterValueUser, 
        setFilterValueUser,
        filterValueId, 
        setFilterValueId,
        tableTransactionsData, 
        setTableTransactionsData,
        detailDataTransaction, 
        setDetailDataTransaction
      }}>{children}</TransactionsContext.Provider>
    );
  };



  