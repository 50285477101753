import React, {useContext, useEffect, useState, useTransition} from 'react';
import { Form, Button, DatePicker, Select,  Tag,  Input, TimePicker, DatePickerProps, Row, Col } from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import './styles.scss'
import { useTransactionsPerDAy } from '../../Services/useTransactionsPerDay';
import { TransactionsContext } from '../../Context/TransactionsContext.tsx/TransactionsContext';
import moment, { Moment } from 'moment';
import { useAllTransactions } from '../../Services/useAllTransactions';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/es'; // Importa el idioma español si lo necesitas
import { useSearchTransactions } from '../../Services/useSearchTransaction';
import { useWindowSize } from '../../hooks/useWindowSize';
import { MobileContext } from '../../Context/MobileContext/MobileContext';

interface filterInformation{
    first_date: Dayjs,
    first_hours: Dayjs,
    second_date: Dayjs,
    second_hours: Dayjs
  }
const tagRender = (props: CustomTagProps) => {

    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };    

    return (
      <Tag
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };  

  export interface NotificationFilters {
    colors: string[] | undefined,
    datePicker: Date[] | undefined,
    machines:  number[] | undefined,
    organizations: number[]| undefined,
  }

export const Panel_SearchTransactions: React.FC =()=>{
  const { 
    setTransactionsLoading, 
    transactionsLoading, 
    setDataOfAllTransactions,
    setSearchTTransactions,
  } = useContext(TransactionsContext)

  const {controllerNavbarBottom, setControllerNavbarBottom} = useContext(MobileContext)
  const {searchTransactionsServise} = useSearchTransactions()
  
  const [form_search_transactions] = Form.useForm();
  const [formData, setFormData] = useState<string>('')

  

  const handleSearch = async (event: any) =>{
    setDataOfAllTransactions(undefined)
    setTransactionsLoading({
      ...transactionsLoading,
      getTransactionsPerDay: true,
    }) 
    setControllerNavbarBottom({
      movementsPerDayRangePicker: false,
      movementsTicketsRangePicker: false,
      searchTicket: false,
      showTickets: false,
      transactionsPerDayRangePicker: false,
      transactionsRangePicker: false,
      transactions: false,
      showTransactions: true
  });
    await searchTransactionsServise(formData)
  }

  const handleChangeSearch = (event: any)=>{
    event.preventDefault()
    const name = event.target.name 
    const value = event.target.value
    setFormData(value)
  };

  const handleReset = ()=>{
    setDataOfAllTransactions(undefined)
    setTransactionsLoading({
      ...transactionsLoading,
      getTransactionsPerDay: false,
    }) 
    setFormData('')
    setSearchTTransactions(undefined) 
    form_search_transactions.setFieldsValue({
      ticketsNumber: ''  
    });
  } 

  return(
    <div className={`Panel_TransactionsTransactions_container ${controllerNavbarBottom.transactions  ? 'visible' : 'hidden'}`}>
      <div className='Panel_TransactionsTransactions_subContainers'>
        <p><div className='Panel_TransactionsTransactions_point'></div>BUSCAR TRANSACCIÓN</p>            
          <Form
            layout="vertical"
            form={form_search_transactions}
          > 
            <Form.Item
              style={{color:'white'}}
              name="ticketsNumber"
              className="Panel_TransactionsTransactions_selectColor">
              <Input
                style={{backgroundColor: 'transparent', color: 'white', borderColor: 'white'}}
                type='number'
                placeholder='N° transacción'
                onChange={handleChangeSearch}
                value={formData}
            />                
            </Form.Item >
            <Form.Item
              className='Panel_TransactionsTransactions_container-btn'          
            >
              <Button
                type="primary"              
                htmlType="submit"
                style={{width:'100%'}}
                onClick={handleSearch}
              >
                Buscar
              </Button>
            </Form.Item>
            {/* <Button
              type="primary"
              style={{width:'100%'}}
              onClick={handleReset}
            >
              Cancelar
            </Button> */}
          </Form> 
      </div>             
    </div>
  )
}

export default Panel_SearchTransactions;
