import { useWindowSize } from '../../hooks/useWindowSize'
import './style.scss'

interface SpinerHorizontalProps{
    info: string,
    fontSize?: string
}

export const SpinerHorizontal: React.FC<SpinerHorizontalProps> =({info, fontSize})=>{
    const { alto, ancho } = useWindowSize()
    return(
        <div className='spinerHorizontal_container'>
            <p style={ancho < 1300 ? {fontSize:fontSize, marginLeft: '20px'} : {fontSize:fontSize} }>{info}</p>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>       
    )
}
