import React, {useContext, useEffect, useState} from 'react';
import { SwapOutlined, DollarOutlined } from '@ant-design/icons';
import './styles.scss'
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { MobileContext,  } from '../../Context/MobileContext/MobileContext';
import { screenSelected } from './types';
import { useNavbarBottom } from '../NavbarBottom/useNavbarBottom';
import { BodyContext } from '../../Context/BodyContext.tsx/BodyContext';
import { TransactionsContext } from '../../Context/TransactionsContext.tsx/TransactionsContext';
import { UserContext } from '../../Context/UserContext/UsersContext';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('Movimientos', 'movimientos', <SwapOutlined />, [
    getItem('Por día', '1'),
    getItem('Ticket', '2'),
  ]),
  getItem('Transacciones', 'transacciones', <DollarOutlined />, [
    getItem('Por día', '3'),
    getItem('Transacciones', '4'),
  ]),
];

export interface HamburgerMenu{
  showMenu: boolean,
  setShowMenu: Function,
  setSelectedScreeen: Function
}

const rootSubmenuKeys = ['movimientos', 'transacciones'];

const HamburgerMenu: React.FC<HamburgerMenu> = ({showMenu, setShowMenu, setSelectedScreeen}) => {

  const {
    setIsVisible, 
    setShowBodyMobile,
    setControllerNavbarBottom,
    controllerNavbarBottom,
    showBodyMobile
  } = useContext(MobileContext)

  const {userData} = useContext(UserContext)

  const { newDataTickets } = useContext(BodyContext)
  const { dataOfAllTransactions } = useContext(TransactionsContext)
  const { mapeoNumeroAShowBody } = useNavbarBottom()

  const [openKeys, setOpenKeys] = useState(['sub1']);
  const [current, setCurrent] = useState('1');
  

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
   
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);      
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(()=>{
    if(showBodyMobile && showBodyMobile[0].idName === 'Movement_perPay_filtro'){
      setOpenKeys(['movimientos']);
      setCurrent('1');
    }
  },[showBodyMobile])

  const onItemClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
    setIsVisible(true);
    setShowMenu(false);
    if(e.key){
      var number = Number(e.key)
      setShowBodyMobile(mapeoNumeroAShowBody[number])//acá seteo las opciones de menú que hay para la pantalla elegida
      setSelectedScreeen(screenSelected[number])
      if(!newDataTickets){
        if(number == 2){
          setTimeout(() => {
            setControllerNavbarBottom({
              ...controllerNavbarBottom,
              movementsTicketsRangePicker: true
            });
          }, 300);
        }   
      }  
      if(!dataOfAllTransactions){
        if(number == 4){
          setTimeout(() => {
            setControllerNavbarBottom({
              ...controllerNavbarBottom,
              transactionsRangePicker: true
            });
          }, 300);
        }   
      }  
    }
  };

  return (
    <div className={`hamburgerMenu_container ${showMenu ? 'visible' : 'hidden'}`} >
      <Menu
        theme='dark'
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        onClick={onItemClick}
        style={{ width: 256 }}
        items={items}
        selectedKeys={[current]}
      />
    </div>
  );
};

export default HamburgerMenu;