
export const style={
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
    fontWeight: '500'  
}

export const roeSecond={
    color:'orange',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
    fontWeight: '500'
}


