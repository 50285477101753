import React, { useContext, useState } from 'react';
import  Logo  from '../../assets/images/LogoHorizontalConTransparenciaAmarillo.png'
import {Tooltip} from  'antd';
import { SwapOutlined, HomeOutlined, DollarOutlined, SettingTwoTone, LogoutOutlined } from '@ant-design/icons';
import './styles.scss'
import { BodyContext, Navbar } from '../../Context/BodyContext.tsx/BodyContext';
import { UserContext } from '../../Context/UserContext/UsersContext';
import { useUser } from '../../Services/useUser';
import MovementsFilters from '../MovementsFilters/MovementsFilters';

interface VerticalNavbarProps {
    changeNavbar: Navbar,
    setChangeNavbar: Function
}

export const VerticalNavbar: React.FC<VerticalNavbarProps> =({changeNavbar, setChangeNavbar})=>{
    const {logOut}= useUser()
    
      const {
        setLayerBody,
      } = useContext(BodyContext)

      const { subAgencydata, userData } = useContext(UserContext)
    

    return(
        <div className="verticalNavbar_container">
            <div className="verticalNavbar_icons_container">  
                <div className={ changeNavbar === 'home' ? 'verticalNavbar_icon backgroundOfSelected' : 'verticalNavbar_icon'}>
                    <div className={changeNavbar === 'home' ? 'verticalNavbar_selected' : 'verticalNavbar_not_selected'}></div>
                    <div className='verticalNavbar_tooltipAndIcon'>
                        <Tooltip
                            placement="right" 
                            title='Inicio'
                        >
                            <HomeOutlined 
                                style={{ fontSize: '16px', color: 'white', margin: '15px 0px' }} 
                                onClick={()=>{
                                    setChangeNavbar('home')
                                    setLayerBody('home')
                                }}
                            />  
                        </Tooltip>  
                    </div>   
                </div>
                <div className='verticalNavbar_divide'></div>
                <div className={ changeNavbar === 'movements' ? 'verticalNavbar_icon backgroundOfSelected' : 'verticalNavbar_icon'} >
                    <div  className={changeNavbar === 'movements' ? 'verticalNavbar_selected ' : 'verticalNavbar_not_selected'}></div>
                    <div className='verticalNavbar_tooltipAndIcon'>
                        <Tooltip
                            placement="right" 
                            title='Moviminetos'
                        >
                            <SwapOutlined  style={{ fontSize: '16px', color: 'white', margin: '15px 0px' }}
                                onClick={()=>{
                                    setChangeNavbar('movements')
                                    setLayerBody('movements')
                                }}
                            />  
                        </Tooltip>  
                    </div>   
                </div>
                <div className={ changeNavbar === 'transactions' ? 'verticalNavbar_icon backgroundOfSelected' : 'verticalNavbar_icon'} >
                    <div  className={changeNavbar === 'transactions' ? 'verticalNavbar_selected ' : 'verticalNavbar_not_selected'}></div>
                    <div className='verticalNavbar_tooltipAndIcon'>
                        <Tooltip
                            placement="right" 
                            title='Transacciones'
                        >
                            <DollarOutlined  style={{ fontSize: '18px', color: 'white', margin: '15px 0px' }}
                                onClick={()=>{
                                    setChangeNavbar('transactions')
                                    setLayerBody('transactions')
                                }}
                            />  
                        </Tooltip>  
                    </div>   
                </div>
                <div className='verticalNavbar_divide'></div>
                <div className={ changeNavbar === 'logout' ? 'verticalNavbar_icon backgroundOfSelected' : 'verticalNavbar_icon'}>
                    <div className={changeNavbar === 'logout' ? 'verticalNavbar_selected' : 'verticalNavbar_not_selected'}></div>
                    <div className='verticalNavbar_tooltipAndIcon'>
                    <Tooltip
                        placement="right" 
                        title='Cerrar sesión'
                    >
                        <LogoutOutlined  
                            style={{ fontSize: '16px', color: 'white', margin: '15px 0px' }}
                            onClick={()=>{
                                logOut()
                            }} 
                        />   
                    </Tooltip> 
                    </div>   
                </div>            
            </div>
        </div>
    )
}

export default VerticalNavbar;