import React, { useContext, useEffect, useState } from 'react';
import { BodyContext } from '../../Context/BodyContext.tsx/BodyContext';
import AnimationLoading from '../../Components/Spiner/AnimationLoading';
import { UserContext } from '../../Context/UserContext/UsersContext';
import { useGetUserData } from '../../Services/useGetUserData';
import './styles.scss'
import { usePerDayReport } from '../../Services/usePerDayReport';
import MobileNavbar from '../../ComponentsMobile/MobileNavbar/MobileNavbar';
import HamburgerMenu from '../../ComponentsMobile/HamburgerMenu/HamburgerMenu';

import NavbarBottom from '../../ComponentsMobile/NavbarBottom';
import { MobileContext } from '../../Context/MobileContext/MobileContext';
import { Screen_MovementsPerDay } from '../../ComponentsMobile/Screen_MovementsPerDay/Screen_MovementsPerDay';
import { ScreenData } from '../../ComponentsMobile/HamburgerMenu/types';
import { Screen_AllTransactions } from '../../ComponentsMobile/Screen_AllTransactions/Screen_AllTransactions';
import { Screen_TransactionsPerDate } from '../../ComponentsMobile/Screen_TransactionsPerDate/Screen_TransactionsPerDate';
import Panel_MovementFiltersPerDay from '../../ComponentsMobile/Panel_MovementFiltersPerDay/Panel_MovementFiltersPerDay';
import Panel_MovementsFiltersTickets from '../../ComponentsMobile/Panel_MovementsFiltersTickets/Panel_MovementsFiltersTickets';
import { Screen_MovementsTickets } from '../../ComponentsMobile/Screen_MovementsTickets/Screen_MovementsTickets';
import Panel_SearchTickets from '../../ComponentsMobile/Panel_SearchTickets/Panel_SearchTickets';
import Panel_TransactionsTransactions from '../../ComponentsMobile/Panel_SearchTransactions/Panel_SearchTransactions';
import { Panel_TransactionsRangePicker } from '../../ComponentsMobile/Panel_TransactionsRangePicker/Panel_TransactionsRangePicker';


export const DashboardMobile: React.FC =()=>{
    
    const { startDatePerDay, endDatePerDay,} = useContext(BodyContext)
    const {filterByDatePerDate } = usePerDayReport()
    const {loading, caballoNegroToken, userData, setUserData} = useContext(UserContext)
    const {data: isLogueado} = useGetUserData(caballoNegroToken)
    const [showMenu, setShowMenu] = useState<boolean>(false)  
    const [selectedScreen, setSelectedScreeen] = useState<ScreenData>()

    useEffect(()=>{
        if(isLogueado){
            setUserData(isLogueado)
            if(userData?.nivel_agencia == '1' ){
                filterByDatePerDate(startDatePerDay, endDatePerDay, userData.id_agencia)
            }  
        }
    },[isLogueado])

    
    return(
        <div className="dashboardMobile_container">
            {loading && <AnimationLoading/>}
            <div className='dashboardMobile_navbar'>
                <MobileNavbar setShowMenu={setShowMenu} showMenu={showMenu} setSelectedScreeen={setSelectedScreeen}  />
            </div>
            <HamburgerMenu 
                showMenu={showMenu}
                setShowMenu={setShowMenu}  
                setSelectedScreeen={setSelectedScreeen}  
            />   
            {
                selectedScreen &&
                <p className='dashboardMobile_screenTitle'> {selectedScreen.label}</p>
            }
            { 
                selectedScreen?.name === 'movementsPerDay' &&
                <>
                    <Screen_MovementsPerDay/>
                    <Panel_MovementFiltersPerDay />
                </>
            }
            {
                selectedScreen?.name === 'movementsTickets' && 
                <>   
                    <Screen_MovementsTickets/>                 
                    <Panel_MovementsFiltersTickets/>  
                    <Panel_SearchTickets/>                  
                </>            
            }
            {
                selectedScreen?.name === 'transactionsPerDay' &&
                <>
                    <Screen_TransactionsPerDate/>
                    <Panel_TransactionsRangePicker selectedScreen={selectedScreen}/>
                </>                
            }
            {   
                selectedScreen?.name === 'transactions' && 
                <>
                    <Screen_AllTransactions/>
                    <Panel_TransactionsRangePicker selectedScreen={selectedScreen}/>
                    <Panel_TransactionsTransactions/>                   
                </>
            }
            <NavbarBottom/>                             
        </div>
    )
}
