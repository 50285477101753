import { Col, Modal, Row, Select } from "antd"
import { ModalSelectAgencieProps } from "./types"
import { UserContext } from "../../Context/UserContext/UsersContext";
import { useContext } from "react";
import { LoadingOutlined } from '@ant-design/icons';

export const MoalSelectAgencie: React.FC<ModalSelectAgencieProps> = ({
    showModal,
    listAgencies,
    handleChangeAgencie,
    handleChange,
    setShowModal,
    loadingSubAgencias
}) =>{

    const { Option } = Select;

    const {
        listSubAgencies,
        selectedAgency,
        subAgencydata
    } = useContext(UserContext)

    const handleCancel = ()=>{
        setShowModal(false)
    }

    return(
        <Modal
            open={showModal && listAgencies && listAgencies.length >0}
            title='Seleccionar agencia'
            onCancel={handleCancel}
            footer={null}
        >
            <Row
                style={{width: '100%'}}
            >
                <Select
                    showArrow
                    style={{width: '100%'}}
                    onChange={(e)=> handleChangeAgencie(e)}
                    placeholder="Agencia"                                        
                    defaultValue= {subAgencydata?.descripcion}
                >
                    { listAgencies?.map((agency)=> (
                        <Option value={agency.id_agencia} key={agency.descripcion}>
                            {agency.descripcion}
                        </Option>
                    ))}
                </Select>
            </Row>           
            <Row
                style={{
                    width: '100%',
                    marginTop: '20px',
                }}
            >      
                {
                    listSubAgencies.length > 0 && !loadingSubAgencias
                    && <Select
                        showArrow
                        style={{
                            width: '100%',
                            marginBottom: '10px'
                        }}
                        onChange={(e)=>{
                            handleChange(e)
                            handleCancel()}
                        }
                        placeholder="Subagencia"
                        defaultValue= {subAgencydata?.descripcion}
                    >
                        { listSubAgencies?.map((agency)=> (
                            <Option value={agency.id_agencia} key={agency.descripcion}>
                                {agency.descripcion}
                            </Option>
                        ))}
                    </Select>  
                    
                }                      
                {
                    loadingSubAgencias && 
                    <>
                        <p
                            style={{
                                margin: '0px 10px 0px 0px',
                                padding: '0px',                                
                            }}
                        >
                            Cargando subagencias
                        </p>
                        <LoadingOutlined />
                    </>
                    
                }
            </Row>            
        </Modal>
    )
}
