import React, { useContext } from 'react'
import { axiosConsolaCaballoNegro, axiosInstance } from '../axios';
import{API_END_POINT_REPORTE_TICKETS_DATA, API_END_POINT_REPORTE_TOKEN_RENEG, API_END_POINT_REPORTE_TICKET_SOLO} from '../axios/consolaIntegrationCaballoNegro';
import { notification } from 'antd';
import { UserContext } from '../Context/UserContext/UsersContext';
import { BodyContext } from '../Context/BodyContext.tsx/BodyContext';
import { addSecondsToDate } from '../utils/addSecondsToDate';
import { useUser } from './useUser';


export const useTicketData =  ()=>{

  const { 
    caballoNegroToken, 
    userData, subAgencydata, 
    setLoading,
    tokenExpirationTime,
    tokeRefreshTime,
    setTokeRefreshTime,
    setTokenExpirationTime,
    regenerateToken,
    setCaballoNegroToken,
    setRegenerateToken,
  } = useContext(UserContext)

  const {
    setTicketDetail,
  } = useContext(BodyContext)

  const id_according_to_level = userData?.nivel_agencia == '1' ? userData.id_agencia : subAgencydata?.id_agencia

  const {logOut}= useUser()

  const fetcher = async (
      ticketNumber: string,
      newToken?: string,
      ): Promise<void>  => {
      setLoading(true)
      try {
        const {
          data,
        }: { data: any } = await axiosConsolaCaballoNegro.get(
          `${API_END_POINT_REPORTE_TICKETS_DATA}?token=${caballoNegroToken}&agencia=${id_according_to_level}&num_ticket=${ticketNumber}`
        );          
        if(data.respuesta.error === '0'){
          setLoading(false)
          setTicketDetail(data.registros)
        }else {
          setLoading(false)
          notification.error({
            message: 'Error', 
            description:
              'ha ocurrido un error, comuníquese con el administrador', 
            placement: 'top'
          })
        }
      } catch (newError) {
        setLoading(false)
        console.log(newError);
        throw newError;
      }
    };

    const ticketData = async(
      ticketNumber: string
    )=>{
      {
        let currentDateAndTime = new Date();
        if(tokenExpirationTime && tokeRefreshTime){

          let date_token_vida = new Date(tokenExpirationTime)
          let date_token_regen = new Date(tokeRefreshTime)
          if(currentDateAndTime < date_token_vida ){ //consulto si se venció el token
            fetcher(ticketNumber )
          }else{ 
            try {
              const {
                data,
              }: { data: any } = await axiosConsolaCaballoNegro.get(
              `${API_END_POINT_REPORTE_TOKEN_RENEG}?token=${regenerateToken}`
              );          
              if(data){
                setLoading(false)
                switch(data.error){
                  case '0':  
                    let token_life = addSecondsToDate(data.token_vida)            
                    let token_regen_life = addSecondsToDate(data.token_reneg_vida)   
                    setCaballoNegroToken(data.token)  
                    setRegenerateToken(data.token_reneg)
                    setTokenExpirationTime(token_life.date)
                    setTokeRefreshTime(token_regen_life.date)  
                    fetcher(ticketNumber, data.token_reneg)
                    break;
                  case '-3': 
                    logOut()
                    notification.error({
                      message: 'Logout', 
                      description:
                        'La sesión se ha vencido, vuelva a loguearse',  
                      placement: 'top'
                    })
                  break;
                  default:
                    console.log(data.error)
                    break;
                }
              }
            } catch (newError) {
              logOut()
              setLoading(false)
              console.log(newError);
              throw newError;
            }       
          }          
        }
      }
    }
    
   return{ticketData}
}