import { Col, Row, Tooltip } from "antd";
import React, { useCallback, useContext, useState } from "react";
import { TicketsPerDayReport } from "../../Services/useGetPerDayReport";
import { BodyContext } from "../../Context/BodyContext.tsx/BodyContext";
import { TicketsReport } from "../MovementsTickets/MovementsTickets";

export interface RowDataSaldo {
    totalBet: string,
    totalWon: string,
    totalReturned: string,
    total: string,
    
}

interface TotalBalane{
    moneda: string,
    startDateTickets: string,
    endDateTickets: string,
    type: 'perDay' | 'tickets'
}
export const TotalBalance: React.FC<TotalBalane> =({
    moneda,
    startDateTickets,
    endDateTickets,
    type
})=>{
    const {
        newDataTickets,
        newDataPerDay
    } = useContext(BodyContext)

    const style={
        color:'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid gray',
        padding: '5px',
    }

    const roeSecond={
        color:'gray',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid gray',
        padding: '5px'
    }
    const styleTickets={
        color:'gray',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid gray',
        padding: '5px',
        cursor:'pointer'
    }
    
    const styleBalance={
        color:'orange',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid gray',
        padding: '5px'
    }
    
    const totalBalance = useCallback (()=>{
        let totalApuesta = 0;
        let totalDevolución = 0;
        let totalGanado = 0;

        let data = type ==='tickets' ? newDataTickets : newDataPerDay
        data?.map((item: TicketsReport | TicketsPerDayReport)=>{
            if(item.cancelado !='1'){
                totalApuesta = totalApuesta + parseFloat(item.total_apuesta);
                totalDevolución = totalDevolución + parseFloat(item.total_devolucion);
                totalGanado = totalGanado + parseFloat(item.total_ganado);
            }
        })
        let totalBalance= {
            totalApuesta: totalApuesta,
            totalDevolución: totalDevolución,
            totalGanado: totalGanado,
            totalSaldo:  totalApuesta - totalDevolución - totalGanado
        }

        let totalBetCalculated = Intl.NumberFormat("de-DE").format(totalBalance.totalApuesta) + ' ' + moneda
        let totalWonCalculated = Intl.NumberFormat("de-DE").format(totalBalance.totalGanado) + ' ' + moneda
        let totalReturned = Intl.NumberFormat("de-DE").format(totalBalance.totalDevolución) + ' ' + moneda
        let totalSaldo = Intl.NumberFormat("de-DE").format(totalBalance.totalSaldo) + ' ' + moneda

        return {
            totalBet: totalBetCalculated,
            totalWon: totalWonCalculated,
            totalReturned: totalReturned,
            total: totalSaldo
        }

    }, [newDataTickets, newDataPerDay])

    const calculateNumberOfTickets = useCallback(() =>{
        let numeberOfTickets: number = 0
        newDataPerDay?.map((item)=>{
            numeberOfTickets = numeberOfTickets + parseInt(item.cantidad_tickets)
        })
        return numeberOfTickets 
    }, [newDataPerDay])

    const TotalTicketsTooltip = ()=>{ //NO BORRAR, SE VA A USAR MÁS ADELANTE
        let canceled = 0
        let not_canceled = 0
        let data = type ==='tickets' ? newDataTickets : newDataPerDay

        if(type ==='tickets'){
           newDataTickets?.map((item)=>{
                if (item.cancelado === '1'){
                    canceled = canceled + 1
                }
            }) 
            not_canceled = newDataTickets?.length ? newDataTickets?.length - canceled : 0
        }
        return(
            <>
                <p>Cancelados: {canceled}</p>
                <p>No Cancelados: {not_canceled}</p>
            </>
        )
    }

    const TicketsCanceled = ()=>{
        let n = 0
        let total = 0
        newDataTickets?.map((item)=>{
            if (item.cancelado === '1'){
                n = n + 1
            }
        })
        if(newDataTickets){
            total =  newDataTickets.length - n
        }
        return total
    }

    
    return( totalBalance &&
        <>
            <Row >
                <Col style={style} span={3}>Fecha desde</Col>
                <Col style={style} span={3}>Fecha hasta</Col>
                <Col style={style} span={2}>Total Tickets</Col>
                <Col style={style} span={4}>Total apostado</Col>
                <Col style={style} span={4}>Total Ganado</Col>
                <Col style={style} span={4}>Total Devuelto</Col>
                <Col style={style} span={4}>Saldo Total</Col>
            </Row>
            <Row style={{marginBottom: '20px'}}>
                <Col style={roeSecond} span={3}>{startDateTickets}</Col>
                <Col style={roeSecond} span={3}>{endDateTickets}</Col>
                <Col style={roeSecond} span={2}>{type === 'tickets' ?  TicketsCanceled() : calculateNumberOfTickets() } </Col>  
                <Col style={roeSecond} span={4}>{totalBalance().totalBet}</Col>
                <Col style={roeSecond} span={4}>{totalBalance().totalWon}</Col>
                <Col style={roeSecond} span={4}>{totalBalance().totalReturned}</Col>
                <Col style={styleBalance} span={4}>{totalBalance().total}</Col>
                
            </Row>
        </>
    )
}