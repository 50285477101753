import React, { useContext, useState } from 'react';
import { PrivateRouteProps } from './types';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Dashboard } from '../../Pages/Dashboard/Dashboard';
import { useWindowSize } from '../../hooks/useWindowSize';
import { DashboardMobile } from '../../Pages/DashboardMobile/DashboardMobile';

export const PrivateRoutes = () => {

    const { alto, ancho } = useWindowSize()
    
    return (
        <Routes>
            <Route path='/' element={
                ancho < 1300 && alto < 915 ?
                <DashboardMobile/> : <Dashboard/> } 
            />
            <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
    );
};

