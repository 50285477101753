
export const style={
    color:'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid gray',
    padding: '5px',
}

export const roeSecond={
    color:'gray',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid gray',
    padding: '5px'
}
export const styleTickets={
    color:'gray',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid gray',
    padding: '5px',
    cursor:'pointer'
}

export const styleBalance={
    color:'orange',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid gray',
    padding: '5px'
}