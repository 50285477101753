import React from 'react';
import  Logo  from '../../assets/images/LogoHorizontalConTransparenciaAmarillo.png'
import Row from  'antd';
import { HomeOutlined } from '@ant-design/icons';
import './styles.scss'

export const Home: React.FC =()=>{

    return(
        <div className="home_container">
            <div className='home_title_container'>
                <HomeOutlined style={{ fontSize: '18px', color: 'white', marginLeft: '20px'}} />    
                <p>Inicio</p>
            </div>
            <div className='home_body_container'></div>
        </div>
    )
}

export default Home;

