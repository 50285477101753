import React, {useContext, useEffect, useState} from 'react';
import { UserContext } from '../../Context/UserContext/UsersContext';
import { Button, Col, Dropdown, MenuProps, Row, Select } from 'antd';
import { useGetListSubAgencies, Record } from '../../Services/useGetListSubAgencies';
//import { Option } from 'antd/es/mentions';
import './styles.scss'
import { BodyContext } from '../../Context/BodyContext.tsx/BodyContext';
import { useTicketsReport } from '../../Services/useTicketsReport';
import { usePerDayReport } from '../../Services/usePerDayReport';
import { useTransactionsPerDAy } from '../../Services/useTransactionsPerDay';
import { TransactionsContext } from '../../Context/TransactionsContext.tsx/TransactionsContext';
import { useAllTransactions } from '../../Services/useAllTransactions';
import { SpinerHorizontal } from '../../Components/SpinerHorizontal/SpinerHorizontal';
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined, DownOutlined } from '@ant-design/icons';
import { MobileContext } from '../../Context/MobileContext/MobileContext';
import { screenSelected } from '../HamburgerMenu/types'; 
import { useNavbarBottom } from '../NavbarBottom/useNavbarBottom';
import { useGetlistsAgencies } from '../../Services/useGetlistsAgencies';
import { useListAgencies } from '../../Services/useListAgencies';
import { useUser } from '../../Services/useUser';
import { MoalSelectAgencie } from '../ModalSelectAgencie';

interface MobileNavbar{
    setShowMenu: Function,
    showMenu: boolean,
    setSelectedScreeen: Function
}

export const MobileNavbar: React.FC<MobileNavbar> =({setShowMenu, showMenu, setSelectedScreeen})=>{
    const {
        caballoNegroToken,
        setListSubAgencies,
        listSubAgencies,
        setSubAgencyData,
        subAgencydata,
        userData,
        selectedAgency, 
        setSelectedAgency
    } = useContext(UserContext)

    const { 
        startDateTickets,
        endDateTickets,
        setNewDataTickets,
        setNewDataPerDay,
        setLoadingBody, 
        loadingBody,
        setTicketsLoading,
        ticketsLoading
    } = useContext(BodyContext)

    const {
        dateAndHoursAllTransactions, 
        setDataOfAllTransactions, 
        setDataGroupedOfTransactions,
        setTransactionsLoading,
        transactionsLoading
    } = useContext(TransactionsContext)

    const {
        setIsVisible,
        setShowBodyMobile,
        setControllerNavbarBottom
    } = useContext(MobileContext)

    const items: MenuProps['items'] = [
        {
          label: (
            <a onClick={()=>logOut()}>
              Cerrar sesión
            </a>
          ),
          key: '0',
        },
      ];

    const {data: record} = useGetListSubAgencies(caballoNegroToken)
    const {data: listAgencies} = useGetlistsAgencies(caballoNegroToken)
    const {filterByDate} = useTicketsReport()
    const {filterByDatePerDate } = usePerDayReport()
    const {filterTransactionsPerDay} = useTransactionsPerDAy()
    const {filterAllTransactions} = useAllTransactions()    
    const { mapeoNumeroAShowBody } = useNavbarBottom()
    const {getListAgencias} = useListAgencies()
    const { Option } = Select;

    const [loadingSubAgencias, setLoadingSubAgencias] = useState<boolean>(false)
    const [showModal, setShowModal] = useState<boolean>(false)
    const {logOut}= useUser()

    useEffect(()=>{
        if(record && userData?.nivel_agencia == '0'){
            setListSubAgencies(record)            
        }
    },[record])

    const handleShowMenu = () =>{
        if(subAgencydata || userData?.nivel_agencia === '1'){
            setShowMenu(!showMenu)
            setControllerNavbarBottom({
                movementsPerDayRangePicker: false,
                movementsTicketsRangePicker: false,
                searchTicket: false,
                showTickets: false,
                transactionsPerDayRangePicker: false,
                transactionsRangePicker: false,
                transactions: false,
                showTransactions: false
            })
        }        
    }

    const handleChange = (event: string)=>{   
        const date = new Date();
        const currentMonth =  date.getMonth() +1 
        const year = date.getFullYear()
        const perDay_StartDate= `${year}/${currentMonth}/01`
        const perDay_endDate= currentMonth == 12 ? `${year+1}/01/01` : `${year}/${currentMonth +1}/01`
                 
        setSelectedScreeen(screenSelected[1])
        setShowBodyMobile(mapeoNumeroAShowBody[1])
        setIsVisible(true)
        const selectedSubagency = listSubAgencies.filter((x)=> x.id_agencia == event)[0]
        setSubAgencyData(selectedSubagency)
       
        //Por defecto consulto las fechas para Movimientos 'por día'
        setLoadingBody({
            ...loadingBody,
            perDayReport: true
            }) 
        setNewDataPerDay(undefined)
        filterByDatePerDate(perDay_StartDate, perDay_endDate, selectedSubagency.id_agencia)
   
        //Por defecto consulto las fechas para Transacciones 'por día'
        setDataGroupedOfTransactions(undefined)
        setTransactionsLoading({
            ...transactionsLoading,
            getTransactionsPerDay: true              
          })
        filterTransactionsPerDay(perDay_StartDate,'00:00:00', perDay_endDate, '00:00:00', selectedSubagency.id_agencia) 

        if(startDateTickets && endDateTickets){
            setNewDataTickets(undefined)
            setTicketsLoading({
                ...ticketsLoading,
                loading: true,
              }) 
            filterByDate(startDateTickets, endDateTickets,  selectedSubagency.id_agencia)
        }      
        if(dateAndHoursAllTransactions){
            setDataOfAllTransactions(undefined)
            setTransactionsLoading({
                ...transactionsLoading,
                getTransactionsPerDay: true              
            })
            const DyH = dateAndHoursAllTransactions
            filterAllTransactions(DyH.firstDate, DyH.firstHours, DyH.secondDate, DyH.secondHours, selectedSubagency.id_agencia) 
        }                             
    }

    const callback = () =>{
        setLoadingSubAgencias(false)
    }

    const handleChangeAgencie = (id: string) =>{
        setLoadingSubAgencias(true)
        const selectedAgency = listAgencies?.filter((x)=> x.id_agencia == id)[0]
        setSelectedAgency(selectedAgency)
        setSubAgencyData(undefined)
        getListAgencias(id, callback)              
    }
    const Selects: React.FC = () =>{
        switch(userData?.nivel_agencia){
            case '0':
                return <>
                    {record && record.length > 0 ?
                        <div className='navbar_select'>

                        <Select
                            showArrow
                            style={{ width: '200px' }}
                            onChange={handleChange}
                            placeholder="Subagencia"                            
                            defaultValue= {subAgencydata?.descripcion}
                        >
                            { record.map((agency: Record)=> (
                                <Option value={agency.id_agencia} key={agency.descripcion}>
                                    {agency.descripcion}
                                </Option>
                            ))}
                        </Select>
                        </div> 
                        :  userData?.agencia_nombre 
                            ? <div><SpinerHorizontal info={'Cargando agencias'} fontSize='12px'/></div>
                            :<div></div>
                }
                </>               
            case '1':
                return <>
                    {
                        userData && userData?.agencia_nombre
                        ? <p>{userData?.agencia_nombre}</p>
                        : <div><SpinerHorizontal info={'Cargando agencias'} fontSize='12px'/></div>
                    }
                    
                </>;
                
            case '3':
                return <>
                  {   
                    listAgencies && listAgencies.length > 0
                        ? <Button
                            style={{
                                width: '200px'
                            }}
                            onClick={()=>setShowModal(true)}
                        >
                            {subAgencydata? subAgencydata.descripcion : 'Seleccionar agencia'}
                        </Button>
                    : <SpinerHorizontal info={'Cargando agencias'} fontSize='12px'/>
                               
                    }
                </>;
            default:
                return <></>;           
        }
    }    

    return(
        <div className="mobileNavbar_container">
            {showModal && listAgencies && listAgencies.length > 0 && 
                <MoalSelectAgencie
                    showModal={showModal}
                    handleChange={handleChange}
                    handleChangeAgencie={handleChangeAgencie}
                    listAgencies={listAgencies}
                    setShowModal={setShowModal}
                    loadingSubAgencias={loadingSubAgencias}
                />}
            <div className="mobileNavbar_row">
                <div onClick={handleShowMenu}>
                    {
                        showMenu 
                        ? <MenuFoldOutlined style={{fontSize: '25px',marginLeft:'5px', marginRight: '20px', color: '#1677ff'}}/>
                        : <MenuUnfoldOutlined style={{fontSize: '25px',marginLeft:'5px', marginRight: '20px', color: subAgencydata || userData?.nivel_agencia === '1' ? 'white' : '#80808094' }}/>
                    }                    
                </div>           
                {/* <p>{ userData?.agencia_nombre}</p> */}
                <Row
                    style={{
                        width: '100%'
                    }}
                    // style={{
                    //     display: 'flex',
                    //     alignItems: 'center',
                    //     width:'100%',
                    //     justifyContent:'space-around'
                    // }}
                >
                    <Col 
                        span={21}
                        style={{
                            display: 'flex',
                             alignItems: 'center',
                             justifyContent:'center'
                         }}
                    >
                        {
                            userData && userData.id_agencia
                            ? <Selects/> 
                            : <SpinerHorizontal info={'Cargando'} fontSize='12px'/>
                        }
                        
                    </Col>
                    <Col 
                        span={3}
                        style={{
                           display: 'flex',
                            alignItems: 'center',
                            justifyContent:'center'
                        }}>
                        <Dropdown 
                            menu={{ items }}
                            trigger={['click']}
                        >
                            <UserOutlined  style={{fontSize:'17px'}}onClick={(e) => e.preventDefault()}/> 
                        </Dropdown> 
                    </Col>              
                 
                </Row>                              
            </div>            
        </div>
    )
}

export default MobileNavbar;