import React, { useContext, useEffect, useState } from "react";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import { Button } from "antd";
import { BodyContext } from "../../Context/BodyContext.tsx/BodyContext";
import { ExportXLSXTransactionsProps, dataXLSX } from "./type";
import { TransactionsContext } from "../../Context/TransactionsContext.tsx/TransactionsContext";

const ExportXLSXTransactions: React.FC<ExportXLSXTransactionsProps> = ({data}) => {

  const { newDataTickets } = useContext(BodyContext)
  const {
    dataOfAllTransactions,
    tableTransactionsData,
  } = useContext(TransactionsContext)

  const [customers, setCustomers] = useState(excelRowData());

  useEffect(()=>{
      let tickets: dataXLSX[] = [];
      if(tableTransactionsData){
        tableTransactionsData.map((item: dataXLSX)=>{
          tickets.push( {
            credit: item.credit,
            debit: item.debit,
            fecha: item.fecha,
            id: item.id,
            monto: item.monto,
            transactionType: item.transactionType, 
            userId: item.userId,
            roundId: item.roundId
          })
        })
        setCustomers(tickets)
      }    
  },[tableTransactionsData])

  useEffect(()=>{
      let tickets: dataXLSX[] = [];
     
      if(dataOfAllTransactions){
        dataOfAllTransactions.map((item: dataXLSX)=>{
          tickets.push( {
            credit: item.credit,
            debit: item.debit,
            fecha: item.fecha,
            id: item.id,
            monto: item.monto,
            transactionType: item.transactionType, 
            userId: item.userId,
            roundId: item.roundId
          })
        })
        setCustomers(tickets)
      }
     
  },[dataOfAllTransactions])
  
  function excelRowData(){
    let tickets: dataXLSX[] = [];
    data.map((item)=>{
      tickets.push( {
        credit: item.credit,
        debit: item.debit,
        fecha: item.fecha,
        id: item.id,
        monto: item.monto,
        transactionType: item.transactionType, 
        userId: item.userId,
        roundId: item.roundId
      })
    })
    return tickets
  }

  const wscols = [
    { wch: Math.max(...customers.map(customer => (customer.credit).toString.length > 9 ? customer.credit + 2 : 9)) },
    { wch: Math.max(...customers.map(customer => customer.debit.length +2)) },
    { wch: Math.max(...customers.map(customer => customer.fecha.length +2)) },
    { wch: Math.max(...customers.map(customer => customer.id.length + 2)) },
    { wch: Math.max(...customers.map(customer => customer.monto.length)) },
    { wch: Math.max(...customers.map(customer => 21)) },
    { wch: Math.max(...customers.map(customer => customer.userId.length)) },
    { wch: Math.max(...customers.map(customer => 14))}
    

  ];
  // ******** XLSX with object key as header *************
  // const fileType =
  //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  // const fileExtension = ".xlsx";

  // const exportToCSV = (csvData, fileName) => {
  //   const ws = XLSX.utils.json_to_sheet(csvData);
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(data, fileName + fileExtension);
  // };

  // ******** XLSX with new header *************
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const Heading = [
    {
      credit: 'Crédito',
      debit: 'Débito',
      fecha: 'Fecha',
      id: 'Id',
      monto: 'Monto',
      transactionType: 'Tipo de transacción', 
      userId: 'id usuario',
      roundId: 'N° de ticket'
    }
  ]

  const fileName = "Transacciones"

  const exportToXLSX = () => {
    const ws = XLSX.utils.json_to_sheet(Heading, {
      header: ["credit",
      "debit",
      "fecha",
      "id",
      "monto",
      "transactionType", 
      "userId",
      "roundId"],
      skipHeader: true,
    });
    ws["!cols"] = wscols;
    XLSX.utils.sheet_add_json(ws, customers, {
      header: ["credit",
      "debit",
      "fecha",
      "id",
      "monto",
      "transactionType", 
      "userId",
      "roundId",],
      skipHeader: true,
      origin: -1 //ok
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      type="ghost"
      className="movements_upload"
      onClick={e => exportToXLSX()}
    >
      Exportar tabla
    </Button>
  );
};

export default ExportXLSXTransactions;
