import React, {
    createContext,
    useState,
  } from 'react';
import { TicketsPerDayReport } from '../../Services/useGetPerDayReport';
import { RowTicketsData, TicketsReport } from '../../Components/MovementsTickets/MovementsTickets';

  class VoidContext implements BodyContext {
    get typeMovements(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setTypeMovement(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get layerBody(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setLayerBody(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get startDateTickets(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setStartDateTickets(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get endDateTickets(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setEndDateTickets(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get startDatePerDay(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setStartDatePerDay(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get endDatePerDay(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setEndDatePerDay(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get newDataTickets(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setNewDataTickets(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    // get searchTickets(): never {
    //   throw new Error('Cannot consume context outside of provider');
    // }
    // get setSearchTickets(): never {
    //   throw new Error('Cannot consume context outside of provider');
    // }
    
    get newDataPerDay(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setNewDataPerDay(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get ticketDetail(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setTicketDetail(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get selectedTicket(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setSelectedTicket(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get ticketTransactions(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setTicketTransactions(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get loadingBody(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setLoadingBody(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get seeAllTickets(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setSeeAllTickets(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get isLoadingAllTickets(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setIsLoadingAllTickets(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get ticketsLoading(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setTicketsLoading(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get searchTicketsNull(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setSearchTicketsNull(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get ticketsPerDayLoading(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setTicketsPerDayLoading(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get showFilters(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setShowFilters(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get tableTicketData(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setTableTicketData(): never {
      throw new Error('Cannot consume context outside of provider');
    }
  }

  interface BodyContext {
    typeMovements: String, 
    setTypeMovement: Function,
    layerBody: Navbar,
    setLayerBody: Function,
    startDateTickets: string | undefined,
    setStartDateTickets: Function,
    endDateTickets: string | undefined,
    setEndDateTickets:Function,
    startDatePerDay: string,
    setStartDatePerDay: Function,
    endDatePerDay: string,
    setEndDatePerDay: Function,
    newDataTickets: TicketsReport[] | undefined,
    setNewDataTickets: Function,
    //searchTickets: TicketsReport[] | undefined,
    searchTicketsNull: string | undefined,
    setSearchTicketsNull: Function,
    //setSearchTickets: Function,
    newDataPerDay: TicketsPerDayReport[] | undefined,
    setNewDataPerDay: Function,
    ticketDetail: TicketDetail | undefined,
    setTicketDetail: Function,
    selectedTicket: TicketsReport | undefined,
    setSelectedTicket: Function,
    ticketTransactions: TicketTransactions[] |  undefined,
    setTicketTransactions: Function,
    ticketsPerDayLoading:boolean,
    setTicketsPerDayLoading: Function
    ticketsLoading:{
      loading:boolean
      balanceTable: boolean,
    },
    setTicketsLoading: Function
    loadingBody:{
      ticketsReport: boolean,
      perDayReport: boolean,
    },
    setLoadingBody: Function
    isLoadingAllTickets:boolean,
    setIsLoadingAllTickets: Function,
    showFilters: boolean,
    setShowFilters: Function,
    tableTicketData: RowTicketsData[] | undefined,
    setTableTicketData: Function,
    seeAllTickets:boolean,
    setSeeAllTickets: Function
  }

  interface propsWithChildren {
    children: React.ReactNode
  }

  interface TicketDetail {
    caballo_1:string,
    caballo_2: string,
    caballo_3: string,
    caballo_4: string,
    caballo_5: string,
    caballo_6: string,
    cantidad_caballos: string,
    combinaciones: string,
    combinada: string,
    descripcion: string,
    distinta_carrera: string,
    importe: string,
    importe_vale: string,
    item: string,
    vales: string,
    
  }

  export interface TicketTransactions{
    fecha: string,
    id: string,
    monto: string,
    transactionId: string,
    transactionType: string  
  }

  export type Navbar = 'movements' | 'transactions' | 'home' | 'logout'

  
  export const BodyContext = createContext<BodyContext>(new VoidContext());
  export const BodyContextProvider= ({ children }: propsWithChildren): JSX.Element => {
    const date = new Date();
    const currentMonth =  date.getMonth() +1 
    const year = date.getFullYear()
    const tickets_StartDate= `${year}/${currentMonth}/01`
    const ticket_endDate= currentMonth == 12 ? `${year+1}/01/01` : `${year}/${currentMonth +1}/01`

    const perDay_StartDate= `${year}/${currentMonth}/01`
    const perDay_endDate= currentMonth == 12 ? `${year+1}/01/01` : `${year}/${currentMonth +1}/01`
 
    const [typeMovements, setTypeMovement] = useState<string>('per_day')
    const [layerBody, setLayerBody] = useState<Navbar>('movements')

    const [startDateTickets, setStartDateTickets] = useState<string | undefined>()
    const [endDateTickets, setEndDateTickets] = useState<string | undefined>()
    
    const [startDatePerDay, setStartDatePerDay] = useState<string>(perDay_StartDate)
    const [endDatePerDay, setEndDatePerDay] = useState<string>(perDay_endDate)

    const [newDataTickets, setNewDataTickets] = useState<TicketsReport[] | undefined>()
    const [searchTicketsNull, setSearchTicketsNull] = useState<string | undefined >()

    const [newDataPerDay, setNewDataPerDay] = useState<TicketsPerDayReport[] | undefined>()
    const [ticketsPerDayLoading, setTicketsPerDayLoading] = useState<boolean>(false)

    const [ticketDetail, setTicketDetail] =useState<TicketDetail| undefined>()
    const [selectedTicket, setSelectedTicket] =useState<TicketsReport | undefined>() 

    const [isLoadingAllTickets, setIsLoadingAllTickets] = useState(false)

    const [ticketTransactions, setTicketTransactions]   = useState<TicketTransactions[] | undefined>()
    const [showFilters, setShowFilters] = useState<boolean>(true)
    const [tableTicketData, setTableTicketData] = useState< RowTicketsData[] | undefined>()
    const [seeAllTickets, setSeeAllTickets] = useState<boolean>(false)

    const [ticketsLoading, setTicketsLoading]=useState<{
      loading:boolean
      balanceTable: boolean,
    }>(
      {
        loading: false,
        balanceTable: false,
      }
    )

    const [loadingBody, setLoadingBody]=useState<{
      ticketsReport: boolean,
      perDayReport: boolean,
    }>(
      {
        ticketsReport: false,
        perDayReport: true,
      }
    )
   
    return (
      <BodyContext.Provider value={{
        typeMovements, 
        setTypeMovement,
        layerBody, 
        setLayerBody,
        startDateTickets, 
        setStartDateTickets,
        endDateTickets, 
        setEndDateTickets,
        startDatePerDay, 
        setStartDatePerDay,
        endDatePerDay, 
        setEndDatePerDay,
        newDataTickets, 
        setNewDataTickets,
        newDataPerDay, 
        setNewDataPerDay,
        ticketDetail, 
        setTicketDetail,
        selectedTicket, 
        setSelectedTicket,
        ticketTransactions, 
        setTicketTransactions,
        loadingBody, 
        setLoadingBody,
        isLoadingAllTickets, 
        setIsLoadingAllTickets,
        ticketsLoading, 
        setTicketsLoading,
        searchTicketsNull, 
        setSearchTicketsNull,
        ticketsPerDayLoading, 
        setTicketsPerDayLoading,
        showFilters,
        setShowFilters,
        tableTicketData, 
        setTableTicketData,
        seeAllTickets, 
        setSeeAllTickets
      }}>{children}</BodyContext.Provider>
    );
  };



  