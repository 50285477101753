import {useState, useContext} from 'react';
import { useNavigate  } from "react-router-dom";
import {  UserOutlined } from '@ant-design/icons';
import { UserContext } from '../../Context/UserContext/UsersContext';
import { Checkbox } from 'antd';

import './login.scss';
import { useUser } from '../../Services/useUser';


export const Login = ()=> {

    const {requestAccess} = useUser()
    const {
       setLoading
    } = useContext(UserContext)
    
    const [form,setForm] = useState({userName:'', password:'', key:''})
    const [showMessageError, setShowMessageError] = useState<string>('')
    const [showPassword, setShowPassword] = useState<boolean>(false)

    const handleSubmit =  async (event: any)=>{
        event.preventDefault()
        setLoading(true);
        setShowMessageError('')
        if(form.userName && form.password && form.key){//check that the fields are complete
            const responseLogin = await requestAccess(form.userName, form.password, form.key)
            if(responseLogin){
                setShowMessageError(responseLogin.mensaje_error)
                setLoading(false);
            }            
        }else{
           setLoading(false);
           setShowMessageError("Debe compeltar todos los campos.")
        }      
    };

    const handleChange = (event: any)=>{
        event.preventDefault()
        const name = event.target.name 
        const value = event.target.value
        setForm({...form,[name]:value})
    };

    const onChange = () => {
        setShowPassword(!showPassword)
    };

  return (
    <div className='login-container' >
        <div className='login-iconUser-container'>
            <UserOutlined className='login-iconUser' style={{color:'white', fontSize: '75px'}}/>
        </div>
        <form className='login-form' onSubmit={handleSubmit}>
            <input
                className='login-form-input'
                type='text'
                name="userName"
                placeholder= "Usuario"
                onChange={handleChange}
                value={form.userName}
            />      
            <input
                className='login-form-input'
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Contraseña"
                onChange={handleChange}
                value={form.password}
            />
            <input
                className='login-form-input'
                type={showPassword ? 'text' : 'password'}
                name="key"
                placeholder="Clave"
                onChange={handleChange}
                value={form.key}
            />
            {/* {showError !== ""  && <span className='login-input-error'>{showError}</span>} */}
            {showMessageError !== ""  && <span className='login-input-error'>{showMessageError}</span>}
            {/* <div className='login-checkContainer'>
                <Checkbox 
                    onChange={onChange}
                    style={{color:'white'}}>
                      Mostrar contraseña
                </Checkbox>
                <button 
                    style={{marginTop:'15px'}}
                    onClick={()=>{
                        setLoading(true)
                    }} 
                > 
                  ¿Olvidaste tu contraseña?
                </button>   
            </div> */}
            <div className='login-btn_login_container'>
                <button 
                    type="submit" 
                    onClick={handleSubmit}
                    id= 'btn'
                >
                    INGRESAR
                </button>
            </div>         
            
        </form>
       
    </div>
    );
}

export default Login;

