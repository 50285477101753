import React, { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../Context/UserContext/UsersContext';
import { Button, Modal, Table, Tooltip  } from 'antd';
import { PlusOutlined, SwapOutlined } from '@ant-design/icons';
import { TicketsPerDayReport } from '../../Services/useGetPerDayReport';
import { BodyContext, TicketTransactions } from '../../Context/BodyContext.tsx/BodyContext';
import { useTicketData } from '../../Services/useTicketData';
import { useTicketTransactions } from '../../Services/useTicketTransactions';
import './styles.scss'
import { SpinerHorizontal } from '../SpinerHorizontal/SpinerHorizontal';
import { ColumnsType, TableProps } from 'antd/es/table';
import { RowDataSaldo, TotalBalance } from '../TotalBalance/TotalBalance';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export interface RowTicketsData {
   id: number,
   numberTickets: string,
   racecourse: string,
   raceDate: string,
   ticketDate: string,
   raceNumber: string,
   user: string,
   userNumber: string,
   totalBet:  string,
   totalWon: string,
   totalReturned: string  
   total: string
   cancelled: any,

}

export interface TicketsReport {
    num_ticket: string,
    num_usuario:string,
    id_hipodromo:string,
    fecha:string,
    num_carrera:string,
    total_apuesta:string,
    total_ganado:string,
    fecha_ticket:string,
    apostador:string | null,
    total_devolucion: string,
    desc_hipodromo:string,
    usuario:string,
    cancelado:string,
    num_verificador:string,
  }

export const MovementsTickets =()=>{

    const {caballoNegroToken, subAgencydata, userData, setLoading} = useContext(UserContext)
    const {
        startDateTickets, 
        endDateTickets, 
        newDataTickets,
        ticketDetail,
        ticketTransactions,
        setTicketDetail,
        setTicketTransactions,
        setSelectedTicket,
        selectedTicket,
        isLoadingAllTickets,
        ticketsLoading,
        searchTicketsNull,
        setTableTicketData,
        seeAllTickets
    } = useContext(BodyContext)
    
    
    const[idAccordingToLevel, setIdAccordingToLeave] =useState<string | undefined>(userData?.nivel_agencia == '1' ? userData.id_agencia : subAgencydata?.id_agencia)
    const {getTicketTransactions} = useTicketTransactions()
    const {ticketData: ticketDataDetail} = useTicketData()
    const detailAndCopy = useRef<any>(null);
    const detailTransaction = useRef<any>(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openModalTransactions, setOpenModalTransactions] = useState(false); 
    const [disablebutosnTransactions, setDisablebutosnTransactions] = useState(false)
    const [imgDetailAndCopy, setImgDetailAndCopy] = useState<string | undefined>()
    const [loadingDownloadPDF, setLoadingDownloadPDF] = useState(false)
   
    useEffect(()=>{
        if(idAccordingToLevel !== subAgencydata?.id_agencia){
            setIdAccordingToLeave(subAgencydata?.id_agencia)
        }
    },[newDataTickets, subAgencydata])//, ticketData

    const moneda = userData?.nivel_agencia == '1' ? userData.moneda_resum : subAgencydata?.moneda_resum

    const rowData= ()=>{
        let data: RowTicketsData[]=[];
        let moneda = userData?.nivel_agencia == '1' ? userData.moneda_resum : subAgencydata?.moneda_resum
       
        newDataTickets?.map((newDataTicket, index)=>{            
            let totalSaldo = Intl.NumberFormat("de-DE").format((parseFloat(newDataTicket.total_apuesta) - parseFloat(newDataTicket.total_devolucion) - parseFloat(newDataTicket.total_ganado))) + ' ' + moneda
            data.push({
                id: index,
                numberTickets: newDataTicket.num_ticket,
                racecourse: newDataTicket.desc_hipodromo,
                raceDate: newDataTicket.fecha,
                ticketDate: newDataTicket.fecha_ticket,
                raceNumber: newDataTicket.num_carrera,
                user: newDataTicket.usuario,
                userNumber: newDataTicket.num_usuario,
                totalBet:  newDataTicket.total_apuesta,
                totalWon: newDataTicket.total_ganado,
                totalReturned: newDataTicket.total_devolucion,
                total: newDataTicket.cancelado === '0'
                    ? totalSaldo
                    : 'Cancelado',
                cancelled: newDataTicket.cancelado
            })
        })
        return data
    }

   const handleRenderer = (text: string, event: RowTicketsData,  ) =>{
        return <Tooltip
                placement="right" 
                title={text}
                > <a
                onClick={()=>{
                 // alert('p')
                }}
                >{text}</a>
            </Tooltip>
    }

    const handleRendererUser =(text: string, event: RowTicketsData,  ) =>{
        return <Tooltip
                placement="right" 
                title={()=><div style={{display:'flex', flexDirection: 'column'}}>
                    <a style={{color: 'white', display:'flex', flexDirection:'row'}}>usuario: {text}</a>
                    <a style={{color: 'white', display:'flex', flexDirection:'row'}}>N° usuario: {event.userNumber}</a>
                </div>}
                > <a
                onClick={()=>{
                }}
                >{text}</a>
            </Tooltip>
    }   

    const renderSaldo = (text: string, event: RowTicketsData)=>{
        let colorTxt = text === 'Cancelado' ? 'red' : 'orange'
        return  <p style={{color:colorTxt}}>
                    {text}
                </p>
          
    }

    const renderPlus = (text: string, event: RowTicketsData)=>{
        let colorTxt = text === 'Cancelado' ? 'red' : 'orange'
        return  <div className='movimentsTickets_saldo'>
                <div className= 'movimentsTickets_container_btn-tickets'>
                    <button 
                        className='movimentsTickets_btn_plus'
                        disabled={disablebutosnTransactions}
                        onClick={()=>{
                            setLoading(true)
                            handleClickDetail(event.numberTickets, 'detail')
                            
                        }}
                    >
                        <PlusOutlined style={{color:'white', fontSize:'10px'}}/>                                       
                    </button>
                    <button 
                        className='movimentsTickets_btn_plus'
                        onClick={()=>{
                            setLoading(true)
                            handleClickDetail(event.numberTickets, 'transaction')
                            
                        }}
                    >
                        <SwapOutlined style={{color:'white', fontSize:'10px'}}/>
                    </button>
                </div>
            </div>
    }
  
    const filtersByIdUser = () => {
        let filter: {text: string, value: string}[] = [];
        if (newDataTickets) {
            newDataTickets.forEach((item) => {
                const value = item.num_usuario;
                if (!filter.some(existingItem => existingItem.value === value)) {
                    filter.push({
                        text: value,
                        value: value,
                    });
                }
                
            });
        }
        return filter;
    };
    
   const columns: ColumnsType<RowTicketsData> = [
    {
        title: 'N° ticket',
        dataIndex: 'numberTickets',
        key: 'numberTickets',
        width: 130,
        ellipsis: true,
        render: handleRenderer,
    },
    {
        title: 'Hipódromo',
        dataIndex: 'racecourse',
        key: 'numberTickets',
        ellipsis: true,
        width: 120,
        render: handleRenderer,
    },
    {
        title: 'Fecha carrera',
        dataIndex: 'raceDate',
        key: 'numberTickets',
        ellipsis: true,
        width: 120,
        render: handleRenderer,
    },
    {
        title: 'Fecha ticket',
        dataIndex: 'ticketDate',
        key: 'numberTickets',
        ellipsis: true,
        width: 200,
        render: handleRenderer,
    },
    {
        title: 'N°C',
        dataIndex: 'raceNumber',
        key: 'numberTickets',
        ellipsis: true,
        width: 60,
        render: handleRenderer,
    },
    {
        title: 'id Usuario',
        dataIndex: 'userNumber',
        width: 160,
        ellipsis: true,
        key: 'numberTickets',
        render: handleRendererUser,
        filters: filtersByIdUser(),
        onFilter: (value, record) => {       
            return typeof value === 'string' && record.userNumber.includes(value);
          },
        filterSearch: true,
    },
    {
        title: 'Apostado',
        dataIndex: 'totalBet',
        width: 110,
        key: 'numberTickets',
        ellipsis: true,
        render: (text: number)=> <a style={{color:'#1677ff'}}>{text}</a>,
    },
    {
        title: 'Ganado',
        dataIndex: 'totalWon',
        key: 'numberTickets',
        width: 110,
        ellipsis: true,
        render: (text: number)=> <a style={{color:'#1677ff'}}>{text}</a>,
    },
    {
        title: 'Devuelto',
        dataIndex: 'totalReturned',
        key: 'numberTickets',
        width: 110,
        ellipsis: true,
        render: (text: number)=> <a style={{color:'red'}}>{text}</a>,
    },
    {
        title: 'Saldo',
        dataIndex: 'total',
        key: 'numberTickets',
        width: 110,
        render: renderSaldo,
    },
    {
        title: 'Detalles',
        dataIndex: 'numberTickets',
        key: 'numberTickets',
        width: 110,
        render: renderPlus,
    },
    
    
];

    const totalBalance = ()=>{
        let totalApuesta = 0;
        let totalDevolución = 0;
        let totalGanado = 0;
        newDataTickets?.map((item: TicketsReport | TicketsPerDayReport)=>{
            if(item.cancelado !='1'){
                totalApuesta = totalApuesta + parseFloat(item.total_apuesta);
                totalDevolución = totalDevolución + parseFloat(item.total_devolucion);
                totalGanado = totalGanado + parseFloat(item.total_ganado);
            }
        })
        let totalBalance= {
            totalApuesta: totalApuesta,
            totalDevolución: totalDevolución,
            totalGanado: totalGanado,
            totalSaldo:  totalApuesta - totalDevolución - totalGanado
        }
        return totalBalance
    }

    //const moneda = userData?.nivel_agencia == '1' ? userData.moneda_resum : subAgencydata?.moneda_resum
    
    const handleClickDetail = async (num_ticket: string, type: string) =>{
        const seleted = newDataTickets?.filter((item)=> item.num_ticket === num_ticket)
        setSelectedTicket(seleted?.[0])
        switch(type){
            case 'detail':

                await ticketDataDetail(num_ticket)
                setIsModalOpen(true) 
                break;
            case 'transaction':
                await getTicketTransactions(num_ticket)
                setOpenModalTransactions(true)                
                break;
        }
    }

    const handleOkDetailTicket = () => {
        handleGeneratePDF()
        setLoadingDownloadPDF(false)
        setIsModalOpen(false);
       // setOpenModalTransactions(false)
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setOpenModalTransactions(false)
        setTicketDetail(undefined)
        setTicketTransactions(undefined)
    };

    const rowDataSaldo= ()=>{
        let moneda = userData?.nivel_agencia == '1' ? userData.moneda_resum : subAgencydata?.moneda_resum    
        let totalBetCalculated = Intl.NumberFormat("de-DE").format(totalBalance().totalApuesta) + ' ' + moneda
        let totalWonCalculated = Intl.NumberFormat("de-DE").format(totalBalance().totalGanado) + ' ' + moneda
        let totalReturned = Intl.NumberFormat("de-DE").format(totalBalance().totalDevolución) + ' ' + moneda
        let totalSaldo = Intl.NumberFormat("de-DE").format(totalBalance().totalSaldo) + ' ' + moneda
        let data: RowDataSaldo[]=[];    
        data.push({
            totalBet: totalBetCalculated,
            totalWon: totalWonCalculated,
            totalReturned: totalReturned,
            total: totalSaldo
        })       
        return data
    }

    const onChange: TableProps<RowTicketsData>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);

        if(extra){
            setTableTicketData(extra.currentDataSource)
        }    
      };

      
    const handleGeneratePDF = () => {
        const input = detailAndCopy.current;
        html2canvas(input)
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'pt',  [352, 406]);
            const imgWidth = pdf.internal.pageSize.getWidth();
            const imgHeight = (canvas.height * imgWidth) / canvas.width; // Calcula la altura proporcional
            const x = (imgWidth - 316.8 ) / 2
            const y = (imgHeight - 365.4  ) / 2; // Centra verticalmente
        
            pdf.addImage(imgData, 'PNG', x, y, 316.8, 365.4);
            pdf.save('Detalle_ticket.pdf');
        });
    };

   
    return(
        <div className='movimentsTickets_containers'> 
           {moneda && newDataTickets && newDataTickets.length !== 0 &&  startDateTickets && endDateTickets &&
                <>
                    <p className='p_gmt'>Zona horaria: GMT-3.</p>
                    <TotalBalance 
                        moneda={moneda} 
                        startDateTickets= {startDateTickets}
                        endDateTickets={endDateTickets}
                        type={'tickets'}
                    />
                </>           
            }   
            {(ticketsLoading.loading) &&  <SpinerHorizontal info={'Loading'}/>}                           
            {(seeAllTickets  && newDataTickets && newDataTickets.length !== 0) && (
                isLoadingAllTickets 
                ? <SpinerHorizontal info={'Loading'}/>                     
                : <Table 
                    columns={columns} 
                    dataSource={rowData()}  
                    scroll={{ x: 1000 }}
                    onChange={onChange}
                />)
            }
            {searchTicketsNull ==='null' && <p style={{color:'red', marginTop:'50px'}}>No se encontraron registros para este ticket</p>}
            {
                imgDetailAndCopy &&
                <Modal
                    open={!!imgDetailAndCopy}
                >
                   <div>
                        <img style={{ width: '100%' }} src={imgDetailAndCopy} />
                    </div> 
                </Modal>
                
            }
            {ticketDetail && 
                    <Modal  
                        open={isModalOpen} 
                        onOk={handleOkDetailTicket} 
                        onCancel={handleCancel} width={400} 
                        footer={[
                            <Button key="back" onClick={handleCancel}>
                              Cerrar
                            </Button>,
                            <Button 
                                key="submit" 
                                type="primary" 
                                loading={loadingDownloadPDF} 
                                onClick={handleOkDetailTicket}>
                              Descargar PDF
                            </Button>,
                          ]}
                    >                        
                        <div style={{display: 'flex', flexDirection:'column', alignItems:'center'}}  ref={detailAndCopy}>
                            <h3 style={{color: 'orange', margin: '10px 0px'}}>DETALLE APUESTA</h3>
                            <div className='movimentsTickets_p-title_transactions'>Ticket: 
                                <p className='movimentsTickets_p-data_transactions'>{selectedTicket?.num_ticket} - {selectedTicket?.num_verificador}</p> 
                            </div>
                            <div className='movimentsTickets_p-title_transactions'>Fecha Ticket: 
                                <p className='movimentsTickets_p-data_transactions'>
                                    {selectedTicket?.fecha_ticket}hs
                                </p>
                            </div>
                            <div className='movimentsTickets_p-title_transactions'>Hipódromo: 
                                <p className='movimentsTickets_p-data_transactions'> {selectedTicket?.desc_hipodromo}</p>
                            </div>
                            <div className='movimentsTickets_p-title_transactions'>Fecha Carrera: 
                                <p className='movimentsTickets_p-data_transactions'> {selectedTicket?.fecha}</p>
                            </div>
                            <div className='movimentsTickets_p-title_transactions'>Número de Carrera: 
                                <p className='movimentsTickets_p-data_transactions'>{selectedTicket?.num_carrera}</p>
                            </div>
                            <h3 style={{color: 'orange', marginTop:'30px', marginBottom:'5px'}}>COPIA BOLETO</h3>
                            <div className='movimentsTickets_p-title_transactions'>
                                <p className='movimentsTickets_p-data_transactions'>{ticketDetail?.descripcion}</p>
                            </div>
                            <p className='movimentsTickets_p-data_transactions' style={{marginLeft:'0px'}}>{ticketDetail?.vales}v({ticketDetail?.combinaciones}) {Number(ticketDetail?.importe_vale).toFixed(2)} {moneda}</p>
                            <p className='movimentsTickets_p-data_transactions' style={{marginLeft:'0px'}}>
                                
                            {ticketDetail?.caballo_1}
                            {ticketDetail?.caballo_2 === '' ? '' : ', ' }{ticketDetail?.caballo_2}
                            {ticketDetail?.caballo_3 === '' ? '' : ', ' }{ticketDetail?.caballo_3}
                            {ticketDetail?.caballo_4 === '' ? '' : ', ' }{ticketDetail?.caballo_4}
                            {ticketDetail?.caballo_5 === '' ? '' : ', ' }{ticketDetail?.caballo_5}
                            {ticketDetail?.caballo_6}
                            
                            </p>
                            <div className='movimentsTickets_p-title_transactions'>Total:
                                <p className='movimentsTickets_p-data_transactions'> {Number(ticketDetail?.importe).toFixed(2)} {moneda}</p>                  
                            </div>
                            
                            <div className='movimentsTickets_p-title_transactions'>Ticket 
                                <p className='movimentsTickets_p-data_transactions'> {selectedTicket?.num_ticket} - {selectedTicket?.num_verificador}</p>
                            </div>
                        </div>
                    </Modal>
                }       
            {ticketTransactions &&
                <Modal  
                    title= 'Transacciones' 
                    open={openModalTransactions} 
                    onCancel={handleCancel}  
                    width={700}
                    footer={[
                        <Button key="back" onClick={handleCancel}>
                          Cerrar
                        </Button>
                      ]}
                >
                    <div className='ref' style={{marginBottom:'20px'}} ref={detailTransaction}>
                        {
                            ticketTransactions?.map((item: TicketTransactions)=>
                            <div className='movimentsTickets_ticket-detail-comtaier'>
                                <div className='movimentsTickets_p-title_transactions'>Fecha: 
                                    <p className='movimentsTickets_p-data_transactions'>{item.fecha}</p>
                                </div>
                                <div className='movimentsTickets_p-title_transactions'>Monto: 
                                    <p className='movimentsTickets_p-data_transactions'>{item.monto} {moneda}</p>
                                </div>
                                <div className='movimentsTickets_p-title_transactions'>Tipo transacción: 
                                    <p className='movimentsTickets_p-data_transactions'>{item.transactionType}</p>
                                </div>
                            </div>  
                            )
                        }                                     
                    </div>
                </Modal>    
            }
        </div>
    )
}

   
