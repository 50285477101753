import { useState, useCallback } from 'react';

type SetStateAction<T> = (prevState: T) => void;

export const useLocalStorageState = <T>(
  key: string,
  defaultValue: T
): [T, SetStateAction<T>] => {
  const [state, setState] = useState(() => {
    try {
      const localStorageValue = window.localStorage.getItem(key);
      if (localStorageValue !== null) {
        return JSON.parse(localStorageValue);
      } else {
        return defaultValue;
      }
    } catch (e) {
      return defaultValue;
    }
  });

  const setLocalStorageState = useCallback(
    (newState: any): void => {
      const newStateString = JSON.stringify(newState)
      window.localStorage.setItem(key, newStateString);
      setState(newState);
    },
    [key]
  );

  return [state, setLocalStorageState];
};